import React from 'react'
import FacebookLogin, { FacebookLoginClient } from '@greatsumini/react-facebook-login'
import { userStore } from '../../App';
import { fetchFacebookLoginOrSignup } from '../../api/auth/socials/facebook/facebookHandler';
import { fetchMe } from '../../api/profile/ProfileController.js';
import { ServerError } from '../../api/ServerError';
import { AlertModel } from '../Alert/AlertModel';

export const FacebookLoginButton = () => {

  const onSuccess = async (response) => {
    console.log('Login Success! Create or login user through our server!', response);

    const answer = await fetchFacebookLoginOrSignup(response)

    const possiblyServerError = ServerError.compare(answer)
    if (possiblyServerError) {
      AlertModel.add(possiblyServerError.description, 'alert-danger')
      FacebookLoginClient.logout((res) => { console.log('logouted from facebook', res) })
      return
    }

    console.log(answer);
    fetchMe()
  }

  const onFail = (error) => {
    console.log('Login Failed!', error);
    userStore.setUser({})
    FacebookLoginClient.logout((res) => { console.log('logouted from facebook', res) })
  }

  const onProfileSuccess = (response) => {

    if (!response.email) {
      FacebookLoginClient.logout()
      console.log('need access to email');
      FacebookLoginClient.logout((res) => { console.log('logouted from facebook', res) })
      return
    }

    userStore.setUser({
      email: response.email,
      id: response.id,
      name: response.first_name,
      surname: response.last_name,
      fromSocial: 'facebook',
      profile: {
        ava: response.picture.data.url
      }
    })
    
    console.log('Get Profile Success!', response);
  }

  const render = ( { onClick, logout } ) => {
    return (
      <>
        <a href='/' className='d-block mx-auto social-login-button' onClick={e => {e.preventDefault(); onClick(e)}} style={{ marginTop: '5px', marginBottom: '7px', fontSize: '1rem' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
          </svg>
        </a>
      </>
    )
  }

  return (
    <FacebookLogin
      appId = {process.env.FACEBOOK_APP_ID}
      onSuccess = {onSuccess}
      onFail = {onFail}
      onProfileSuccess = {onProfileSuccess}
      render = { render }
      fields = "first_name,last_name,email,picture"
      loginOptions={{
        auth_type: 'reauthorize'
      }}  
    />
  )
}
