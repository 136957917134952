import endpoints from "../../api/auth/endpoints";
import { ServerError } from "../../api/ServerError";

// trying to login through API
const fetchLogin = async (email, password) => {
    const result = await endpoints.login({email: email, password: password})
    if (result.data.code === 33) { return new FetchError(result.data.description) } // wrong password
    if (result.data.code === 40) { return new FetchError(result.data.description) } // account not found
    if (result.data.code === 50) { return new FetchError(result.data.description) } // invalid email format
    if (result.data.code === 60) { return new FetchError(result.data.description) } // need email field
    if (result.data.code === 61) { return new FetchError(result.data.description) } // need password field
    if (result.data.code === 71) { return new FetchError(result.data.description) } // password is too short or too long
    if (result.data.code === 72) { return new FetchError(result.data.description) } // password should contain atleast one number and one special character (!@#$%^&*)
    const possiblyServerError = ServerError.compare(result)
    if (possiblyServerError) return possiblyServerError
    return result.data
}

// trying to register through API
const fetchSignup = async (email, password) => {
    const result = await endpoints.signup({email: email, password: password})
    if (result.data.code === 30) { return new FetchError(result.data.description) } // account should be activated
    if (result.data.code === 33) { return new FetchError(result.data.description) } // wrong password
    if (result.data.code === 41) { return new FetchError(result.data.description) } // account exist
    if (result.data.code === 50) { return new FetchError(result.data.description) } // invalid email format
    if (result.data.code === 60) { return new FetchError(result.data.description) } // need email field
    if (result.data.code === 61) { return new FetchError(result.data.description) } // need password field
    if (result.data.code === 71) { return new FetchError(result.data.description) } // password is too short or too long
    if (result.data.code === 72) { return new FetchError(result.data.description) } // password should contain atleast one number and one special character (!@#$%^&*)
    const possiblyServerError = ServerError.compare(result)
    if (possiblyServerError) return possiblyServerError
    return result.data
}

// trying to refresh tokens through API
const fetchRefresh = async () => {
    const result = await endpoints.refresh()
    // if (result.data.code === 63) { return new FetchError(result.data.description, 63) } // refresh token needed
    const possiblyServerError = ServerError.compare(result)
    if (possiblyServerError) return possiblyServerError
    return result.data
}

// try to resend email confirmation link
const fetchActivation = async (email) => {
    const result = await endpoints.activation({email: email})
    const possiblyServerError = ServerError.compare(result)
    if (possiblyServerError) return possiblyServerError
    return result.data
}

// try to send password reset code
const fetchForgot = async (email, code, password) => {
    const result = await endpoints.forgot({email: email, reset_code: code, password: password})
    const possiblyServerError = ServerError.compare(result)
    if (possiblyServerError) return possiblyServerError
    return result.data
}

// API errors
class FetchError {
    constructor(message, code = null) {
        this.type = 'fetchError'
        this.message = message
        this.code = code
    }
}

export { fetchLogin, fetchSignup, fetchRefresh, fetchActivation, fetchForgot, FetchError }