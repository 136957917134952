import React, { useContext } from 'react'
import styles from './photos.module.scss'
import ImageUploading from 'react-images-uploading'
import Image from 'react-bootstrap/Image'
import { usePlan } from '../../../hooks/usePlan'
import { UserStoreContext } from '../../../App'
import { observer } from 'mobx-react'

export const Photos = observer( ({ images, setImages }) => {

  const { user } = useContext(UserStoreContext)
  const plan = usePlan(user.plan)
  const maxNumber = plan ? plan.limitations.maxUploadingFiles : 4
  const acceptType = ['jpg', 'png', 'HEIF', 'jpeg', 'PNG']


  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  return (
    <div className={styles.photosWrapper}>

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        maxFileSize={process.env.REACT_APP_MAX_UPLOADING_PHOTO_SIZE}
        acceptType={acceptType}
        inputProps={{name: 'photos'}}
      >
      {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <div className={styles.insideWrapper} {...dragProps}>
            <div className={styles.uploadSpace} style={{ display: imageList.length >= maxNumber ? 'none' : 'block' }} >
              <div className={styles.dragHere}>Drag photo here or <span onClick={onImageUpload}>select it on your device</span></div>
            </div>
            <div className={styles.divWithImages}>
                {imageList.map((image, index) => (
                  <div className={styles.onePhoto} key={index}>
                    <div className={styles.removeBtn} onClick={() => onImageRemove(index)}><i className="bi bi-trash3"></i></div>
                    <Image src={image.data_url} rounded width={100} height={100}  />
                  </div>
                ))}
            </div>
            <div className={styles.desc}>
                You can upload up to {maxNumber} photos, {process.env.REACT_APP_MAX_UPLOADING_PHOTO_SIZE / 1024 / 1024} MB, With JPG, PNG format
            </div>
            { errors && 
              <div className={styles.error}>
                  {errors.maxNumber && <span>Number of selected images exceed {maxNumber}</span>}
                  {errors.acceptType && <span>Your selected file type is not allow</span>}
                  {errors.maxFileSize && <span>Selected file size exceed {process.env.REACT_APP_MAX_UPLOADING_PHOTO_SIZE / 1024 / 1024} MB</span>}
                  {errors.resolution && <span>Selected file is not match your desired resolution</span>}
              </div>
            }
          </div>
        )}
      </ImageUploading>

      

    </div>
  )
})
