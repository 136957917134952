import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Container, Form, Spinner } from 'react-bootstrap'
import { userStore, UserStoreContext } from '../../App'
import styles from './messages.module.scss'
import lang from '../../translate/en_US'
import { DashboardWarning } from '../../components/DashboardWarning/DashboardWarning'
import { Link, useSearchParams } from 'react-router-dom'
import endpoints from '../../api/endpoints'
import { OneChat } from './OneChat'
import { Header } from './Header'
import { Body } from './Body'
import { NewMessageScreen } from './NewMessageScreen'

export const Messages = observer( () => {

    const limitChats = 10

    const { user } = useContext(UserStoreContext)
    const [chatInput, setChatInput] = useState('')
    const [chats, setChats] = useState([])
    const [selectedChat, setSelectedChat] = useState(null)
    const [responderUser, setResponderUser] = useState(!selectedChat ? null : (selectedChat.members.find(e => e._id !== user._id)))
    const [sending, setSending] = useState(false)
    const inputRef = useRef(null)
    const [page, setPage] = useState(1)
    const [chatsPage, setChatsPage] = useState(1)
    const [messages, setMessages] = useState([])
    const [noScrollNeeded, setNoScrollNeeded] = useState(false)
    const [isHaveMoreChats, setIsHaveMoreChats] = useState(false)
    const [isChatsLoading, setIsChatsLoading] = useState(false)
    const [isChatsWasInit, setIsChatsWasInit] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isStartChattingInfo, setIsStartChattingInfo] = useState(null)
    const [chekChatForExistProcess, setChekChatForExistProcess] = useState(false)
    const [idFromQuery, setIdFromQuery] = useState(null)

    const isShowNeedPhoneAlert = (user && user.profile && !user.profile.phone) || (user && user.profile && user.profile.phone === "") ? true : false



    // decide if new message or just select old chat
    useEffect(() => {

        const setChatFromQuery = async (chatId) => {
            const chatHere = chats.find(e => e._id === chatId)
            if (chatHere) {
                console.log('chats set!');
                searchParams.delete('p')
                setSearchParams(searchParams)
                setIsStartChattingInfo(null)
                setIdFromQuery(null)
                setSelectedChat(chatHere)
            } else {
                console.log('chats here not found');
            }
        }
        
        if (idFromQuery != null && !isStartChattingInfo) {
            setChekChatForExistProcess(true)
            endpoints.isChatExist(idFromQuery)
                .then(result => {
                    console.log('CHAT TEST', result);
                    if (result && result.data && result.data.members) { // if we got chat back, then chat already exist
                        setChatFromQuery(result.data._id)
                    }
                    if (result && result.data && result.data.title) { // if we got posting with user - then will start new chat
                        console.log('set info new msg to', result.data);
                        setIsStartChattingInfo(result.data)
                    }
                    setChekChatForExistProcess(false)
                })
                .catch(error => {
                    setChekChatForExistProcess(false)
                    setIsStartChattingInfo(null)
                    console.log('error while test chat for existing:', error);
                })
                console.log('WE INSIDE 2000', idFromQuery);
        }

        
    }, [searchParams, isChatsWasInit, idFromQuery, chats, isStartChattingInfo])

    // bring Id from query to state
    useEffect(() => {
        const sP = searchParams.get('p')
        if (sP && isChatsWasInit) {
            setIdFromQuery(sP)
        }
    }, [searchParams, isChatsWasInit])

    // add message handler
    const clickHandler = async (e) => {
        e.preventDefault()
        if (chatInput.length > 0) {
            setSending(true)
            const idForCompare = Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9*Math.pow(10, 12)).toString(36)
            // add fake message
            setMessages(old => [...old, { 
                body: chatInput,
                sender: {
                    _id: user._id,
                    profile: {
                        ava: user.profile && user.profile.ava ? user.profile.ava : null
                    }
                },
                chat: selectedChat._id,
                readed: false,
                delivered: false,
                createdAt: Date.now(),
                updatedAt: Date.now(),
                idForCompare: idForCompare
            }])

            setNoScrollNeeded(false)
            // upload message to server and wait for approve
            await endpoints.addMessage({chat: selectedChat._id, body: chatInput, sender: user._id, idForCompare: idForCompare})
                .then(data => {
                    console.log('message delivered: ', data);
                    setChatInput('')
                    setSending(false)
                    if (inputRef.current) inputRef.current.focus()
                    setMessages(oldMessages => {
                        const forReplace = oldMessages.find(m => m.idForCompare === data.data.idForCompare)
                        const forReplaceIndex = oldMessages.findIndex(m => m.idForCompare === data.data.idForCompare)
                        if (forReplace && forReplaceIndex) {
                            let newArr = [...oldMessages]
                            newArr[forReplaceIndex] = data.data
                            return newArr
                        } else {
                            return oldMessages
                        }
                    })
                }) 
                .catch(error => {
                    setSending(false)
                    if (inputRef.current) inputRef.current.focus()
                    console.log('some error while add new message:', error);
                })
        }
    }

    const setChatFromCreateChat = async (chatId) => {
        const chatHere = chats.find(e => e._id === chatId)
        if (chatHere) {
            console.log('chats set!');
            setSelectedChat(chatHere)
        } else {
            console.log('chats here not found');
        }
    }

    // set selected chat
    useEffect(() => {
        if (selectedChat) {
            const resp = selectedChat.members.find( e => e._id !== user._id)
            if (resp) {
                setResponderUser(resp)
            }
            if (inputRef.current) inputRef.current.focus()
        }
    }, [selectedChat, user])

    // start polling
    useEffect(() => {

        userStore._isChat = true
        const controller = new AbortController()

        const subscribe = async () => {
            if(userStore._isChat === false) {
                console.log('get out chat mode');
                return
            }
            let response = await endpoints.getAllChats(controller, {page: 1, limit: limitChats * chatsPage})
            if(response && response.status && response.status === 502) {
                await subscribe()
            } else if (response && response.status && response.status !== 200) {
                console.log('why not 200?:', response.statusText);
                await new Promise(resolve => setTimeout(resolve, 1000))
                await subscribe()
            } else if( response && response.data) {
                console.log('we get data:', response.data);
                setChats(response.data)
                await subscribe()
            } else {
                console.log('what a fuck chat', response);
            }
        }

        subscribe()

        return () => { 
            userStore._isChat = false
            controller.abort()
            console.log('unMOUNT'); 
        }

    }, [chatsPage])

    // when click on 'load more' chats button
    const loadMoreHandle = async (p) => {
        if (isHaveMoreChats) {
            setChatsPage(p)
            endpoints.getAllChats(null, {init: true, page: 1, limit: limitChats * p})
                .then(data => {
                    console.log('we loaded addition chats:', data);
                    if (Array.isArray(data.data) && data.data.length <= 0) return console.log('empty loaded chats data');

                    // if no messages in next request
                    if (Array.isArray(data.data) && data.data.length < limitChats * p) {
                        setIsHaveMoreChats(false)
                        setChats(data.data)
                        return
                    }

                    setIsHaveMoreChats(true)
                    setChats(data.data)
                    return
                })
                .catch(error => {
                    console.log("Error while fetching getChats request:", error);
                })
        } else { console.log('no chats to load'); }
    }

    // initial ask for chats
    useEffect(() => {
        setIsChatsLoading(true)
        endpoints.getAllChats(null, {init: true, page: 1, limit: limitChats })
            .then(data => {
                setIsChatsLoading(false)
                if (Array.isArray(data.data)) {
                    console.log('we get INIT chats:', data);
                    setIsChatsWasInit(true)
                    setChats(data.data)
                    if (data.data < limitChats) setIsHaveMoreChats(false)
                    else setIsHaveMoreChats(true)
                }
            })
            .catch(error => {
                setIsChatsLoading(false)
                console.log("Error while fetching getChats request:", error);
            })
    }, [])

    return (
        <Container className={`d-flex flex-column mt-4 justify-content-center ${styles.mainContainer}`}>
        { console.log(' ♻ Posts page was rendered!') }

        { isShowNeedPhoneAlert &&
            <DashboardWarning>
            {lang.needPhoneNumberWarningText}<Link to='/profile'>{lang.needPhoneNumberWarningLink}</Link> 
            </DashboardWarning>
        }

        <div className={styles.messagesWrapper}>
            <div className={styles.leftSide}>
                { (isChatsLoading || (chats && chats.length < 1)) &&
                    <div className={styles.alerts}>
                        { isChatsLoading && <Spinner animation='border' /> }
                        {(chats && chats.length < 1 && !isChatsLoading) && <div className={styles.haveNoChats}>have no active chat</div>}
                    </div>
                }
                {(chats && chats.length >= 1 && !isChatsLoading) && 
                    chats.map((oneChat, i) => {
                        return (
                            <OneChat key={i}
                                chat = {oneChat}
                                setChat = { setSelectedChat }
                                selectedChat = { selectedChat }
                                setPage = { setPage }
                            />
                        )
                    })
                }
                {(chats && chats.length >= 1 && !isChatsLoading && isHaveMoreChats) && <div className={styles.loadMoreChats}><span onClick={e=>loadMoreHandle(chatsPage + 1)}>Load more</span></div> }
            </div>
            <div className={styles.rightSide}>
                {(!selectedChat && !isStartChattingInfo) && 
                    <div className={styles.selectChat}>
                        Please select chat from left side, or create new chat by clicking text button on any posting page
                    </div>
                }
                { (!selectedChat && isStartChattingInfo) &&
                    <NewMessageScreen newChatInfo={isStartChattingInfo} reloadChats={(newChatId) => { setIsStartChattingInfo(null); setChatFromCreateChat(newChatId); loadMoreHandle(chatsPage + 1);   }} />
                }

                { (selectedChat) &&
                    <div className={styles.chatWrapper}>
                        <Header responderUser={responderUser} selectedChat={selectedChat} setSelectedChat={setSelectedChat} />
                        <Body 
                            chat={selectedChat} 
                            page={page} 
                            setPage={setPage} 
                            messages={messages} 
                            setMessages={setMessages} 
                            noScrollNeeded={noScrollNeeded}
                            setNoScrollNeeded={setNoScrollNeeded}
                        />
                        { (selectedChat && selectedChat.posting) &&
                            <Form className={styles.sendArea} onSubmit={ e => { if(!sending) { clickHandler(e); } else e.preventDefault() }}>
                                <Form.Control type='text' value={chatInput} onChange = {e => setChatInput(e.target.value)} ref={inputRef} className={styles.input} />
                            
                                <div onClick={ e => { if(!sending) { clickHandler(e); } }} className={[styles.sendButton, (chatInput.length <= 0 || sending) ? styles.noTextInput : undefined].join(' ')}>
                                    { !sending ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                        </svg>
                                        :
                                        <Spinner animation='border' size='sm' className={styles.senderSpinner} />
                                    }
                                </div>
                            </Form>
                        }
                    </div>
                }
            </div>

            {chekChatForExistProcess && 
                <div className={styles.chekingForChatExist}>
                    <Spinner animation='border' />
                </div>
            }
        </div>

        </Container>
    )
})
