import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { UserStoreContext } from '../../App'
import { LoadableImage } from '../LoadableImage/LoadableImage'
import { AvaPlaceHolder } from '../Placeholder/avaPlaceholder'
import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../../store/breakpoints.js'

export const CustomAvaButton = observer(({onCLick}) => {

  const { user } = useContext(UserStoreContext)
  const isMobile = useMediaQuery({ query: breakpoints.mobile })
  const isTablet = useMediaQuery({ query: breakpoints.tablet })
  const fromFacebook = user.fromSocial === 'facebook' ? true : false

  return (
    <div className='d-flex align-items-center custom-ava' onClick={onCLick}>
      {isMobile ? null : <div className={`custom-ava-title ${isTablet ? 'on-small' : null}`}>{fromFacebook ? `${user.name} ${user.surname}` : user.email}</div> }
      <LoadableImage src={user && user.profile && user.profile.ava ? user.profile.ava : '/'} width={35} height={35} className='rounded-circle' alt='avatar' >
        <AvaPlaceHolder radius={35} />
      </LoadableImage>
    </div>
  )
})
