import React from 'react'
import { CustomDropDownLink } from './CustomDropDownLink'
import { fetchLogout } from '../../api/profile/ProfileController.js'
import lang from '../../translate/en_US'

export const LogoutBtn = ({isLoading, setIsLoading}) => {
  return (
    <CustomDropDownLink to={'/'} closeMenu={ () => fetchLogout(setIsLoading) } disabled={isLoading} color='rgb(153 36 36)' className='logoutMenuLink' noActive>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-closed" viewBox="0 0 16 16">
        <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z"/>
        <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/>
      </svg>
      {lang.userMenuLogoutButton} {isLoading && lang.userMenuLogoutButtonInProcess}
    </CustomDropDownLink>
  )
}
