import React, { useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { ScreenType } from '..'
import { handleSubmit } from '../handler'
import lang from '../../../translate/en_US'

export const Forms = ({screenType, setScreen, setIsLoading}) => {

  const [emailField, setEmailField] = useState('')
  const [passwordField, setPasswordField] = useState('')
  const [validated, setValidated] = useState(false)
  const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state

  const form = useRef()
  const errorPlaceholder = useRef(null)

  // clear form, errors and email/password values
  const clearForm = () => {
    form.current.reset()          // clear all form fields
    setValidated(false)           // ??????????????????
    setEmailField("")             // set email state to empty
    setPasswordField("")          // set password state to empty
    error()                       // clear all visible errors
  }

  // make UI error / clear UI error if message = null
  const error = (message = null) => {
    if (message) {
      errorPlaceholder.current.innerHTML = `<div class="alert alert-danger mt-2" role="alert">${message}</div>`
    } else {
      errorPlaceholder.current.innerHTML = ''
    }
  }

  // nedded for handlers logic
  const submitProps = {
    email: emailField,              // email that was typed
    password: passwordField,        // password that was typed
    screenType: screenType,         // type of current scrren
    error: error,                   // callback for showing UI error message for user
    setValidated: setValidated,     // ????????????????????
    setIsLoading: setIsLoading,     // callback for toggle the spinner
    setScreen: setScreen,           // callback for change visible screen
    setBtnEnabled: setBtnEnabled    // callback for change btn disability
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={event => handleSubmit(event, submitProps) } id='auth-form' className='mb-2' ref={form} >
          <FloatingLabel controlId="formEmail" label={lang.formInputEmailPlaceholder} className="mb-3">
            <Form.Control
              type='email'
              placeholder={lang.formInputEmailPlaceholder}
              required
              minLength={6} maxLength={100}
              onChange={event => setEmailField(event.target.value)}
              onPaste={event => event.preventDefault()}
              name='email'
              pattern='(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))'
            />
            <Form.Control.Feedback type="invalid">{lang.formInputEmailValidationError}</Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel controlId="formPassword" label={lang.formInputPasswordPlaceholder} className="mb-3">
            <Form.Control
              type='password'
              placeholder={lang.formInputPasswordPlaceholder}
              required
              minLength={8} maxLength={40}
              onChange={event => setPasswordField(event.target.value)}
              onPaste={event => event.preventDefault()}
              name='password'
              pattern='(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}'
            />
            <Form.Control.Feedback type="invalid">
              { screenType === ScreenType.login ? lang.formInputPasswordNeedError : lang.formInputPasswordValidationError}
            </Form.Control.Feedback>
          </FloatingLabel>

          <div className='hstack gap-3 pb-1 pt-0'>
            <a href='/' onClick={ event => { 
                                          event.preventDefault(); 
                                          if(btnEnabled)  {
                                            screenType === ScreenType.login ? setScreen(ScreenType.signup) : setScreen(ScreenType.login)
                                            clearForm(event) 
                                          }
                                        }}> 
              {screenType === ScreenType.login ? lang.formBackToSignUp : lang.formBackToLogin}
            </a>
            <Button type='submit' variant='primary' className='ms-auto' disabled={!btnEnabled  ? 'disabled' : null}>
                {screenType === ScreenType.login ? lang.formLoginButton : lang.formSignupButton}
            </Button>
          </div>
        </Form>
        <div id="errorAlertPlaceholder" ref={errorPlaceholder}></div>
      </>
  )
}
