import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { UserStoreContext } from './App';
import Auth from './components/Auth';
import { Confirmed } from './pages/Confirmed';
import { observer } from 'mobx-react-lite'

import Error404 from './pages/Error404';
import NeedProfile from './pages/NeedProfile';
import Postings from './pages/Postings';
import Archive from './pages/Archive';
import { Profile } from './pages/Profile';
import { AddPosting } from './pages/AddPosting';
import { EditPosting } from './pages/EditPosting';
import { Favorites } from './pages/Favorites';
import { Messages } from './pages/Messages';


export const Router = observer( () => {

  const userStore = useContext(UserStoreContext)

  const guard = (to) => {
    if (userStore.isAuth && userStore.message !== 'NameSurname') return to
    if (userStore.isAuth && userStore.message === 'NameSurname') return <NeedProfile />
    return <Auth />
  }

  return (
    <Routes>
        <Route path='/profile' element={  guard(<Profile />) } />
        <Route path='/favorites' element={  guard(<Favorites />) } />
        <Route path='/messages' element={  guard(<Messages />) } />
        <Route index element={  guard(<Postings />) } />
        <Route path='/postings/add' element={  guard(<AddPosting />) } />
        <Route path='/posting/edit' element={  guard(<EditPosting />) } />
        <Route path='/archive' element={  guard(<Archive />) } />
        <Route path='/confirmed' element={ <Confirmed /> } />
        <Route path='*' element={<Error404 /> } />
    </Routes>
  )
})
