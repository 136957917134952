import React from 'react'
import { ResetCodeConfirmForm } from './form/ResetCodeConfirmForm'

export const Reset = ({setScreen, setIsLoading}) => {
  return (
    <>
        <ResetCodeConfirmForm setScreen={setScreen} setIsLoading={setIsLoading} />
    </>
  )
}
