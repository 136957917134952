import { AlertModel } from "../../components/Alert/AlertModel"
import endpoints from "../endpoints"
import { ServerError } from "../ServerError"
import lang from '../../translate/en_US'
import { userStore } from "../../App"

// update user and profile
const fetchUpdateProfile = async (event, props) => {
    console.log(' 🙎‍♂️ Updating user data ASYNC')
    event.preventDefault()
    event.stopPropagation()
    props.error()
    props.setValidated(true)

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      document.querySelectorAll( ":invalid" )[1].focus();
      return
    }

    props.setIsLoading(true)
    props.setBtnEnabled(false)
    handleUpdateProfile(props)
}


const handleUpdateProfile = async (props) => {

    const fields = {
        name: props.name,
        surname: props.surname,
        phone: props.phone, 
        ava: props.ava
    }

    try {
        const result = await endpoints.updateProfile(fields)
        if (result && result.data && result.data._id) {
            console.log(' 🙎‍♂️ users profile getted!', result.data)
            props.setIsLoading(false)
            props.setBtnEnabled(true)
            if (props.closeDialog) props.closeDialog()
            AlertModel.add(lang.profileProfileWasUpdatedAlert, 'alert-success')
            userStore.setUser(result.data)
            userStore.setMessage(null)
            console.log(' 🙎‍♂️😀 user profile was updated!')
        } else {
            console.log(' 🙎‍♂️ incorrect answer from server');
            props.setIsLoading(false)
            props.setBtnEnabled(true)
            if (props.closeDialog) props.closeDialog()
        }
    } catch(error) {
        console.log(' 🚪 ERROR while update ME: ', error)

        props.setIsLoading(false)
        props.setBtnEnabled(true)
        if (props.closeDialog) props.closeDialog()

        const possibleServerError = ServerError.compare(error)
        if (possibleServerError) {
            AlertModel.add(possibleServerError.description, 'alert-danger')
        }
        if (error instanceof ServerError) {
            AlertModel.add(error.description, 'alert-danger')
        }
    }
}

export { fetchUpdateProfile, handleUpdateProfile }