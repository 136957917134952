import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import lang from '../../translate/en_US'
import style from './UpgradeAlert.module.scss'
import { LoadableImage } from '../LoadableImage/LoadableImage'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

export const UpgradeAlert = ({closeModal, isVisible, type}) => {

  const navigate = useNavigate()

  let src = `${process.env.REACT_APP_SERVER_ADDR}/static/images/plans/basic.jpg`
  if (type === 'vehicles') src = `${process.env.REACT_APP_SERVER_ADDR}/static/images/plans/vehicle.jpg`
  if (type === 'real-estate') src = `${process.env.REACT_APP_SERVER_ADDR}/static/images/plans/realestate.jpg`

  return (
    <Modal 
      show= {isVisible}
      onHide={ e => closeModal() }
      centered
      onClick={ (e) => {e.preventDefault(); e.stopPropagation(); } }
      dialogClassName={style.modal}
      contentClassName={style.content}
    >
      <div className={style.btnClose} onClick={ e => closeModal() }><i className="bi bi-x-lg"></i></div>
      <Modal.Body className={style.body}>
        <LoadableImage className={style.upgradeImg} src={src} width={400} height={300} alt='Upgrade profile'>
          <div className={style.placeholder}><Spinner animation="border" variant="success" /></div>
        </LoadableImage>
        <div className={style.underImage}>
          <h4>
            { (type !== 'vehicles' && type !== 'real-estate') && <span>Postings count exceeded!</span> }
            { type === 'real-estate' && <span>Real Estate postings count exceeded!</span> }
            { type === 'vehicles' && <span>Vehicle postings count exceeded!</span> }
          </h4>
          <div className={style.desc}>
            { (type !== 'vehicles' && type !== 'real-estate') && <span>You limit for postings exceeded, if you want to post more than 20 postings per month - please upgrade your prifile!</span> }
            { type === 'real-estate' && <span>You limit for postings in Real Estate category was exceeded, if you want to post more than 20 postings per month - please upgrade your profile!</span> }
            { type === 'vehicles' && <span>You limit for postings in Vehicle category was exceeded, if you want to post more than 20 postings per month - please upgrade your profile!</span> }
          </div>
          <Button variant='success' className={style.btnUpgrade} onClick={(e) => { window.open('https://guamlist.com/plan', '_blank'); }} >Upgrade</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
