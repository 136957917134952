import React from 'react'
import { ForgotForm } from './form/ForgotForm'

export const Forgot = ({setScreen, setIsLoading}) => {
  return (
    <>
        <ForgotForm setScreen={setScreen} setIsLoading={setIsLoading} />
    </>
  )
}
