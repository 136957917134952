import React from 'react'
import { ScreenType } from '.'
import { Forms } from './form/Forms'
import { Socials } from './Socials'
import lang from '../../translate/en_US'

export const Login = ({setScreen, setIsLoading}) => {

    return (
        <>
            <Forms screenType={ScreenType.login} setScreen={setScreen} setIsLoading={setIsLoading} />
            <div className='strike'><span>{lang.authOrLoginWith}</span></div>
            <Socials />
        </>
    )
}
