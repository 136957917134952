import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import React, { useRef, useState } from 'react'
import { handleSubmit } from '../handler'
import { ScreenType } from '..'
import { userStore } from '../../../App'
import lang from '../../../translate/en_US'

export const ResetCodeConfirmForm = ({setScreen, setIsLoading}) => {

    const form = useRef()
    const errorPlaceholder = useRef(null)
    const [codeField, setCodeField] = useState('')
    const [validated, setValidated] = useState(false)
    const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state
    
    const email = userStore.user && userStore.user.email ? userStore.user.email : ''

    // clear form, errors and email values
    const clearForm = () => {
        form.current.reset()          // clear all form fields
        setValidated(false)           // ??????????????????
        setCodeField("")             // set code state to empty
        error()                       // clear all visible errors
    }

    // make UI error / clear UI error if message = null
  const error = (message = null) => {
    if (message) {
      errorPlaceholder.current.innerHTML = `<div class="alert alert-danger mt-2" role="alert">${message}</div>`
    } else {
      errorPlaceholder.current.innerHTML = ''
    }
  }

  // nedded for handlers logic
  const submitProps = {
    code: codeField,
    email: email,                       // email that was typed
    screenType: ScreenType.resetCode,   // type of current scrren
    error: error,                       // callback for showing UI error message for user
    setValidated: setValidated,         // ????????????????????
    setIsLoading: setIsLoading,         // callback for toggle the spinner
    setScreen: setScreen,               // callback for change visible screen
    setBtnEnabled: setBtnEnabled        // callback for change btn disability
  }

  return (
    <>
        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event, submitProps) } id='auth-form' className='mb-2' ref={form} >
            <FloatingLabel controlId="formCode" label={lang.formInputCodePlaceholder} className="mb-3">
            <Form.Control
                type='text'
                placeholder={lang.formInputCodePlaceholder}
                required
                minLength={4} maxLength={4}
                onChange={event => setCodeField(event.target.value)}
                onPaste={event => event.preventDefault()}
                name='reset_code'
                pattern='\d{4}'
                />
                <Form.Control.Feedback type="invalid">{lang.formInputCodeValidationError}</Form.Control.Feedback>
            </FloatingLabel>

            <div className='hstack gap-3 pb-1 pt-0'>
                <a href='/' onClick={ event => { 
                                            event.preventDefault()
                                            if(btnEnabled) {
                                                setScreen(ScreenType.forgot)
                                                clearForm(event) 
                                            }
                                            }}> 
                {lang.formResendCodeLink}
                </a>
                <Button type='submit' variant='primary' className='ms-auto' disabled={!btnEnabled  ? 'disabled' : null} >{lang.formConfirmButton}</Button>
            </div>
        </Form>
        <div id="errorAlertPlaceholder" ref={errorPlaceholder}></div>
    </>
  )
}
