import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import styles from './paginator.module.scss'

export const Paginator = ({ pages, current, onPageClick, pagesInPaginator }) => {

    const [nowPagesRange, setPagesRange] = useState(1)

    const [nowActive, setNowActive] = useState(current)

    var pagesLinks = [];
    for (var i = 0; i < Math.ceil(pages); i++) {
        pagesLinks.push(1);
    }

    const clickHandler = (e, page) => {
        e.preventDefault()
        if (page === nowActive) return

        setNowActive(page)
        onPageClick(page)
    }

    const clickNextPages = (e) => {
        setPagesRange(old => old + 1)
    }

    const clickPrevPages = (e) => {
        setPagesRange(old => old - 1)
    }

    useEffect(() => {
        setNowActive(current)
    }, [current])

    return (
        <Pagination className={styles.paginationUL}>
            { nowPagesRange > 1 && <Pagination.Ellipsis onClick={clickPrevPages} /> }
            {
                pagesLinks.map((e, i) => {
                    if (i >= ((nowPagesRange - 1) * pagesInPaginator) && i < (nowPagesRange * pagesInPaginator)) {
                        return (
                            <Pagination.Item 
                                key={i+1} 
                                className={`${i+1 === nowActive ? styles.active : ''}`}
                                active = { i+1 === nowActive ? true : false }
                                onClick={e => clickHandler(e, i+1)}
                            >
                                {i+1}
                            </Pagination.Item>
                        )
                    }
                    return null
                })
            }
            { pagesLinks.length > (nowPagesRange * pagesInPaginator) && <Pagination.Ellipsis onClick={clickNextPages} /> }
        </Pagination>
    )
}
