import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export const CustomDropDownLink = ({ to, children, closeMenu, disabled, color, className, noActive }) => {

  const loc = useLocation()
    
  return (
    <Link to={to} className={`dropdown-item ${disabled ? 'disabled' : ''} ${(loc.pathname === to && !noActive) ? 'active' : ''} ${className}`} onClick={() => closeMenu()} style={{ color: color ? color : 'auto' }}>
        {children}
    </Link>
  )
}
