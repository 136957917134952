import React, { useRef, useState } from 'react'
import styles from './LoadableImage.module.scss'

export const LoadableImage = ({ src, alt, width, height, className, onLoad, children, onClick }) => {

  const [isLoaded, setIsLoaded] = useState(false)

  const imageRef = useRef()

  return (
    <div className={styles.container} style={{width: width, height: height, minWidth: width, minHeight: height}} onClick={onClick} >
      <div className={styles.placeholder}>{children}</div>
      <img onLoad={() => { setIsLoaded(true); if(onLoad) onLoad(); }} 
           ref={imageRef} 
           src={src} 
           width={width} 
           height={height} 
           className={[isLoaded ? null : styles.hidden, className].join(" ")} 
           alt={alt} 
           style={{
             objectFit: "cover"
           }}
      />
    </div>
  )
}
