import { useEffect, useState } from "react";
import endpoints from "../api/endpoints";
import { AlertModel } from "../components/Alert/AlertModel";

export function usePlan(planSlug) {

    const [plan, setPlan] = useState(null)

    // load plan from server
    useEffect(() => {
        
        const getPlan = async () => {
            if (!planSlug) return console.log('PLAN SLUG NULL');

            try {
                const plan = await endpoints.getPlan(planSlug)
                if (plan && plan.data) { console.log('plan geted:', plan.data); return setPlan(plan.data)  }
                else AlertModel.add('Server error, try again later', 'alert-danger')
                setPlan(null)
            } catch(error) {
                console.log('error while fetch plan', error);
                setPlan(null)
            }
        }

        getPlan()

    }, [planSlug])

    return plan

}