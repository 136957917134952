import React, { useCallback, useEffect, useState } from 'react'
import endpoints from '../../../api/endpoints'
import { userStore } from '../../../App'
import styles from './body.module.scss'
import { Message } from './Message'

export const Body = ({ chat, page, setPage, messages, setMessages, noScrollNeeded, setNoScrollNeeded }) => {

    const limitMessages = 25
    const [isNoMoreMessages, setIsNoMoreMessage] = useState(false)
    const [isInitLoading, setIsInitLoading] = useState(false)

    // Will add newly loaded messages to main array
    const addLoadedMessagesToArray = (arrOfNewMessages) => {
        setNoScrollNeeded(true)
        let newMessagesArr = []
        arrOfNewMessages.map(oneNewMessage => {
            if (messages.find(e => e._id === oneNewMessage._id)) return true
            else newMessagesArr.push(oneNewMessage)
            return true
        })
        if (newMessagesArr.length > 0) {
            console.log('added new messages:', newMessagesArr);
            setMessages(old => [...newMessagesArr, ...old])
        } else { console.log('nothing to add'); }
    }

    // when click on 'load more' messages button
    const loadMoreHandle = async (p) => {
        if (!isNoMoreMessages) {
            setPage(p)
            endpoints.getMessages(null, {init: true, chat: chat._id, page: p, limit: limitMessages})
                .then(data => {
                    console.log('we loaded addition messages:', data);

                    // if no messages in next request
                    if (Array.isArray(data.data) && data.data.length < limitMessages) {
                        setIsNoMoreMessage(true)
                        addLoadedMessagesToArray(data.data)
                        return
                    }

                    setIsNoMoreMessage(false)
                    addLoadedMessagesToArray(data.data) 
                    return
                })
                .catch(error => {
                    console.log("Error while fetching getMessages INIT request:", error);
                })
        } else { console.log('no messages to load'); }
    }

    // remove all duplicated objects from array by key
    function removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        })
    }

    // check if we get readed msg from API, need update our msg
    const checkReaded = (arrFromApi, arrForChecking) => {
        const checkedArr = []
        arrForChecking.map(oneForCheck => {
            const sameMessageFromAPI = arrFromApi.find(e => e.idForCompare === oneForCheck.idForCompare)
            if (sameMessageFromAPI) {
                checkedArr.push(sameMessageFromAPI)
            } else {
                checkedArr.push(oneForCheck)
            }
            return true
        })
        return checkedArr
    }

    // add msg from polling to our arr
    const addToMessages = useCallback((newMessage) => {
        if (chat._id === newMessage.chat) {
            setNoScrollNeeded(false)
            setMessages(oldMessages => {
                const newArr = [...oldMessages, newMessage]
                const sortedArr = removeDuplicates( newArr, 'idForCompare')
                return sortedArr
            })
        }
    }, [chat, setMessages, setNoScrollNeeded])

    // ask for new updated messages from API every polling reconnect
    const updataActualMessages = useCallback(() => {
        endpoints.getMessages(null, {init: true, chat: chat._id, page: 1, limit: limitMessages})
            .then(data => {
                console.log('Update before Pull again:', data);
                // if no messages in this request
                if (Array.isArray(data.data) && data.data.length > 0 && data.data[0].chat === chat._id) {
                    setMessages(oldMessages => {
                        const newArr = [...oldMessages, ...data.data]
                        const sortedArr = removeDuplicates( newArr, 'idForCompare')
                        return checkReaded(data.data, sortedArr)
                    })
                    return
                }
                return
            })
            .catch(error => {
                console.log("Error while fetching UPDATE before pull request:", error);
            })
    }, [chat, setMessages]) 

    // start polling
    useEffect(() => {

        const controller = new AbortController()

        const subscribe = async () => {
            if(userStore._isChat === false) return console.log('get out messages')
            const chatIdNow = chat._id
            await endpoints.getMessages(controller, { chat: chatIdNow, page: 1, limit: limitMessages})
            .then(response => {
                if(response && response.status && response.status === 502) { console.log('opa 502, reSubscribe'); updataActualMessages(); subscribe() } 
                else if (response && response.status && response.status !== 200 && response.statusText) {
                    console.log('why not 200?:', response.statusText);
                    setTimeout(e => {subscribe(); updataActualMessages()}, 1000)
                } else if( response && response.data) {
                    console.log('we get data messages:', response.data);
                    if (chat._id === chatIdNow) {
                        addToMessages(response.data)
                    }
                    updataActualMessages()
                    subscribe()
                } else {
                    console.log('what a fuck msg', response);
                }
            })
        }

        subscribe()

        return () => { controller.abort(); console.log('unMOUNT message'); }

    }, [chat, addToMessages, updataActualMessages])

    // initial ask for messages
    useEffect(() => {

        if (chat && chat._id && page === 1) {
            setMessages([])
            setIsInitLoading(true)
            endpoints.getMessages(null, {init: true, chat: chat._id, page: 1, limit: limitMessages})
                .then(data => {
                    console.log('we get INIT data:', data);
                    setIsInitLoading(false)
                    setNoScrollNeeded(false)
                    // if no messages in this request
                    if (Array.isArray(data.data) && data.data.length > 0 && data.data[0].chat === chat._id) {
                        setIsNoMoreMessage(false)
                        setMessages(data.data)
                        return
                    }
                    setIsNoMoreMessage(true)
                    setMessages([])
                    return
                })
                .catch(error => {
                    setIsInitLoading(false)
                    console.log("Error while fetching getMessages INIT request:", error);
                })
        }

    }, [chat, page, setMessages, setNoScrollNeeded])

    
    return (
        <div className={styles.chatBody} >
            <div className={styles.upperMessage}>
                { (!isNoMoreMessages && !isInitLoading) && <div className={styles.loadMore} onClick={e=>loadMoreHandle(page + 1)}>Load more</div> }
                { isNoMoreMessages && <div className={styles.lastMessage}>No more messages here</div> }
            </div>
            { (messages && messages.length > 0) &&
                messages.map((oneMessage, i) => {
                    return (
                        <Message message={oneMessage} key={i} noScrollNeeded={noScrollNeeded} />
                    )
                })
            }
        </div>
    )
}