import React, { Component } from 'react'
import lang from '../../translate/en_US'

export default class Spinner extends Component {
  render() {
    return (
      <div className={`position-absolute d-flex justify-content-center align-items-center top-0 start-0 w-100 h-100 rounded-3 bg-dark bg-opacity-25 ${ this.props.isVisible ? 'd-block' : 'd-none' }`}>
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">{lang.spinnerLoading}</span>
        </div>
      </div>
    )
  }
}
