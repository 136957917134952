import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import Cookies from 'js-cookie';
import { userStore } from '../../App.js';
import { AlertModel } from '../../components/Alert/AlertModel.js';
import endpoints from '../endpoints.js'
import { ServerError } from '../ServerError';

// take user from server
const fetchMe = async () => {
    console.log(' 🙎‍♂️ Fetching user data ASYNC')
    try {
        const result = await endpoints.me()
        if (result === undefined) return console.log('PIZDA');
        if (result && result.data && result.data._id) {
            console.log(' 🙎‍♂️ user getted!', result.data)
            userStore.setUser(result.data)
            console.log(' 🙎‍♂️😀 user was updated in store')
        } else {
            if (result && result.data && result.data.code && result.data.code === 44) {
                console.log(' 🙎‍♂️ ' + result.data.message);
                userStore.setMessage('NameSurname')
            }
            console.log(' 🙎‍♂️ incorrect answer from server');
        }
    } catch(error) {
        console.log(' 🙎‍♂️ ERROR while fetching "ME": ', error)
        const possibleServerError = ServerError.compare(error)
        if (possibleServerError) {
            AlertModel.add(possibleServerError.description, 'alert-danger')
        }
        if (error instanceof ServerError) {
            AlertModel.add(error.description, 'alert-danger')
        }
        if (userStore.accessToken && userStore.accessToken != null && userStore.accessToken !== '') {
            console.log(' 🙎‍♂️ since we are logged in, try again after 10 sec')
            setTimeout(fetchMe, 10000)
        }
    }
}

// logout the client
const fetchLogout = async (setIsLoading) => {
    console.log(' 🚪 trying to logout ASYNC')
    setIsLoading(true)
    try {
        const result = await endpoints.logout()
        if (result.data && result.data === 'SUCCESS') {
            setIsLoading(false)
            console.log(' 🙎‍♂️ user getted!', result.data)
            Cookies.remove('access_token', { domain: 'guamlist.com' })
            Cookies.remove('refresh_token', { domain: 'guamlist.com' })  
            Cookies.remove('isa', { domain: 'guamlist.com' }) 
            userStore.setUser({})
            userStore.setAccessToken(null);
            userStore.setIsAuth(false)
            userStore.setMessage(null)
            FacebookLoginClient.logout()
        } else {
            setIsLoading(false)
            console.log(' 🙎‍♂️ incorrect answer from server');
        }
    } catch(error) {
        setIsLoading(false)
        console.log(' 🚪 ERROR while logout: ', error)
        const possibleServerError = ServerError.compare(error)
        if (possibleServerError) {
            AlertModel.add(possibleServerError.description, 'alert-danger')
        }
        if (error instanceof ServerError) {
            AlertModel.add(error.description, 'alert-danger')
        }
    }
}


export { fetchMe, fetchLogout }