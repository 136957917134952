import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { LoadableImage } from '../../../components/LoadableImage/LoadableImage';
import styles from './onePost.module.scss'
import lang from '../../../translate/en_US'
import { getPrevUrlForPostingPhoto } from '../../../helpers/functions';
import endpoints from '../../../api/endpoints';
import { AlertModel } from '../../../components/Alert/AlertModel';
import { DangerAlert } from '../../../components/DangerAlert/DangerAlert';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

export const OnePost = ({ post, categories, deleteCallback }) => {
    console.log(post);

    const navigation = useNavigate()
    const [withoutImage, setWithoutImage] = useState(false)
    const [errorImage, setErrorImage] = useState(false)
    const [category, setCategory] = useState('...')
    const [subcategory, setSubcategory] = useState('...')
    const [isDeleteAlertVisible, setIsDeleteAlertVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // set "no photo" pic for listing without photos
        if (post.photos && Array.isArray(post.photos) && post.photos.length < 1) {
            setWithoutImage(true)
            setErrorImage(false)
        }

        // set "error photo" pic for listing which photos not found
        if (post.photos && Array.isArray(post.photos) && post.photos.length > 0) {
            setWithoutImage(false)
            setErrorImage(false)
            var http = new XMLHttpRequest()
            http.open('HEAD', post.photos[0], false)
            http.send();
            if (http.status === 404) setErrorImage(true)
        }

    }, [post])

    useEffect(() => {
        if (post.category && categories && Array.isArray(categories)) {
            const c = categories.find(oneCat => oneCat.slug === post.category)
            if (c) {
                setCategory(c.title)
                const sc = c.subcategories.find(oneSubCat => oneSubCat.slug === post.subcategory)
                if (sc) setSubcategory(sc.title)
            }

        }
    }, [categories, post])

    const deleteHandler = async (e) => {
        setIsLoading(true)
        setIsDeleteAlertVisible(false)
        try {
            const answer = await endpoints.deletePosting({posting: post._id})
            if (answer && answer.data && answer.data === 'SUCCESS') { 
                setIsLoading(false)
                deleteCallback(true)
            } else {
                AlertModel.add('Can\'t delete posting', 'alert-danger')
                setIsLoading(false)
            }
        } catch (error) {
            console.log('error while trying delete posting', error);
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.onePostWrapper} >
            <div className={styles.onePost}>

                <div className={styles.leftSide} onClick={(e) => {window.open(process.env.REACT_APP_SITE_ADDR + '/posting/' + post._id, '_blank').focus()} }>
                    { (!withoutImage && !errorImage) && 
                        <LoadableImage className={styles.image} src={getPrevUrlForPostingPhoto(post.photos[0])} width={60} height={60}>
                            <div className={styles.placeholder}><i className="bi bi-arrow-repeat"></i></div>
                        </LoadableImage>
                    }
                    { (withoutImage === true) && <div className={styles.tempImg}><div className={styles.placeholder}><i className="bi bi-image"></i></div></div> }
                    { (errorImage) && <div className={styles.tempImg}><div className={styles.placeholder}><i className="bi bi-exclamation-diamond"></i></div></div> }

                    <div className={styles.titleWrapper}>
                        <div className={styles.title}><span>{post.title}</span></div>
                        <div className={styles.categories}>
                            <span>{category} <i className="bi bi-arrow-right"></i> {subcategory}</span>
                        </div>
                        <div className={styles.price}>
                            <span>
                                {(post.price && post.price > 0) ? <i className="bi bi-currency-dollar"></i> : ''}
                                {post.price && post.price > 0 ? post.price : lang.freePostingText}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={styles.rightSide}>
                    <Button 
                        className={styles.edit} 
                        disabled={isLoading} 
                        onClick={ (e) => { e.preventDefault(); e.stopPropagation(); navigation(`/posting/edit?id=${post._id}&back=postings`) } }
                    >
                        { isLoading ? 
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                            </svg>
                        }
                    </Button>

                    <Button 
                        className={styles.danger} 
                        disabled={isLoading} 
                        onClick={ (e) => { e.preventDefault(); e.stopPropagation(); setIsDeleteAlertVisible(true) } }
                    >
                        { isLoading ? 
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> 
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                            </svg>
                        }
                    </Button>
                </div>

                <DangerAlert 
                    isVisible={isDeleteAlertVisible}
                    isFooter 
                    actionButtonText={'Delete'} 
                    closeModal={ (e) => { setIsDeleteAlertVisible(false)} }
                    fireAction={ deleteHandler }
                >
                    <div>Do you want to delete this posting?</div>
                    <div className={styles.undone}>This action cant be undone</div>
                </DangerAlert>
            </div>
        </div>
    )
}