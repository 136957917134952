import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './edit.module.scss'
import Button from 'react-bootstrap/Button'
import { Photos } from './Photos'
import Form from 'react-bootstrap/Form'
import lang from '../../translate/en_US'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import InputGroup from 'react-bootstrap/InputGroup'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useCategories } from '../../hooks/useCategories'
import { useLocations } from '../../hooks/useLocations'
import Spinner from '../../components/Spinner'
import { AlertModel } from '../../components/Alert/AlertModel'
import endpoints from '../../api/endpoints'
import { UpgradeAlert } from '../../components/UpgradeAlert/UpgradeAlert'
import Error404 from '../Error404'
import { getBase64FromUrl, urlToFile } from '../../helpers/functions'

export const EditPosting = () => {

  const [searchParams] = useSearchParams();
  const navigation = useNavigate()
  const categories = useCategories()
  const locations = useLocations()
  const [images, setImages] = useState([])
  const [tempImages, setTempImages] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState(null)
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [price, setPrice] = useState(null)
  const [location, setLocation] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isUpgrade, setIsUpgrade] = useState(false)
  const [upgradeType, setUpgradeType] = useState('total')
  const [nowPosting, setNowPosting] = useState(null)
  const [isPhotosLoading, setIsPhotosLoading] = useState(false)

  const [validator, setValidator] = useState(
    {
      vCategory: false,
      vSubcategory: false,
      vFilters: {},
    }
  )

  // changed category field
  const categoryChanged = (e) => {
    if (categories) {
      setSelectedCategory(categories.find(elem => elem.slug === e.target.value))
    }
    setSelectedSubcategory(null)
    setSelectedFilters(null)
    setTitle(null)
    setValidator(e => {return {...e, vCategory: false, vSubcategory: false, vFilters: {} }})
  }

   // changed subcategory field
  const subcategoryChanged = (e) => {
    if (selectedCategory && selectedCategory.subcategories) {
      const subcat = selectedCategory.subcategories.find(elem => elem.slug === e.target.value)
      setSelectedSubcategory(subcat)
      // will set empty filters for this subcategory
      if (subcat && subcat.filters) {
        const newFilters = {}
        subcat.filters.map((f) => {newFilters[f.slug] = null; return true})
        setSelectedFilters(newFilters)
      } else {
        setSelectedFilters(null)
      }
      setTitle(null)
    }
    setValidator(e => {return {...e, vCategory: false, vSubcategory: false, vFilters: {} }})
  }

  // one of filters was changed
  const filtersChanged = (e, filterSlug) => {
    if (e.target.value === lang.formSelectAnyFilterPlaceholder) {
      setSelectedFilters((old) => {
        return {...old, [filterSlug]: null}
      })
    } else {
      setSelectedFilters((old) => {
        return {...old, [filterSlug]: e.target.value}
      })
      setValidator(e => {return {...e, vFilters: {...e.vFilters, [filterSlug]: false} }})
    }
  }

  // submit click
  const submitHandle = (e) => {
    e.preventDefault()

    // validation
    if (!selectedCategory) { setValidator(e => {return {...e, vCategory: true }}); return false; }
    if (selectedCategory && !selectedSubcategory) { setValidator(e => {return {...e, vSubcategory: true }}); return false; }

    // validate required filters
    if (selectedSubcategory.filters) { 
      for (const key in selectedFilters) {
        const fullFilter = selectedSubcategory.filters.find(e => e.slug === key)
        if (fullFilter && fullFilter.required && !selectedFilters[key]) {
          console.log('nado pole', key);
          setValidator(e => {return {...e, vFilters: {...e.vFilters, [key]: true} }})
          return false
        }
      }
    }

    // valid all
    setValidator(e => {return {...e, vCategory: false, vSubcategory: false, vFilters: {} }})

    setIsLoading(true)

    const formData = new FormData(e.target)

    images.map((i) => {
      formData.append('photos', i.file)
      return true
    })
    
    endpoints.editPosting(formData)
    .then((resonse) => {
      setIsLoading(false)
      if (resonse && resonse.data && resonse.data._id) {
        setIsLoading(false)
        navigation(searchParams.get("back") && searchParams.get("back") === 'archive' ? '/archive' : '/')
      } else {
        console.log('error while get response for edit posting');
        AlertModel.add('Something goes wrong', 'alert-danger')
        setIsLoading(false)
      }
    })
    .catch((error) => {
      console.log('error while edit posting', error);
      if (error.code && error.code === 80802) { setUpgradeType('total'); setIsUpgrade(true) }
      else if (error.code && error.code === 80803) { setUpgradeType('real-estate'); setIsUpgrade(true) }
      else if (error.code && error.code === 80804) { setUpgradeType('vehicles'); setIsUpgrade(true) }
      else AlertModel.add('Server error, try again later', 'alert-danger')
      setIsLoading(false)
    })

  }

  // load posting to field
  useEffect(() => {
    const postingId = searchParams.get("id")

    const getPosting = async () => {
      try {
        const p = await endpoints.getOnePosting(postingId)
        if (p && p.data) { 
          setIsLoading(false)
          return setNowPosting(p.data)
        }
        else AlertModel.add('Can\'t load posting', 'alert-danger')
        setIsLoading(false)
      } catch(error) {
        console.log('error whuile fetch one posting for me', error);
        setIsLoading(false)
      }
    }

    getPosting()

  }, [searchParams])

  // load data from posting to form
  useEffect(() => {

    const loadPostToForm = async () => {
      if (nowPosting && categories && locations) {
        setTempImages([])
        const cat = categories.find(elem => elem.slug === nowPosting.category)
        const subcat = cat.subcategories.find(elem => elem.slug === nowPosting.subcategory)
        setSelectedCategory(cat)
        setSelectedSubcategory(subcat)
        if(nowPosting.title) setTitle(nowPosting.title)
        if(nowPosting.price) setPrice(nowPosting.price)
        else setPrice(0)
        if(nowPosting.description) setDescription(nowPosting.description)
        console.log(nowPosting.location);
        if(nowPosting.location) setLocation((locations.find(elem => elem.slug === nowPosting.location.slug)).slug)
        if(nowPosting.filters) {
          console.log('filters:', nowPosting.filters);
          const newFilters = {}
          nowPosting.filters.map((oneFilter) => {
            newFilters[oneFilter.slug] = oneFilter.value
            return true
          })
          setSelectedFilters(newFilters)
        }
        if(nowPosting.photos) {
          setIsPhotosLoading(true)
          nowPosting.photos.map(async (oneLink) => {
            const oneImgObj = {}
            oneImgObj.data_url = await getBase64FromUrl(oneLink)
            const filename = oneLink.replace(/^.*[\\\/]/, '')
            oneImgObj.file = await urlToFile(oneImgObj.data_url, filename, 'image/png')
            return setTempImages(old => [...old, oneImgObj])
          })
        }
      }
    }

    loadPostToForm()

  }, [nowPosting, categories, locations])

  // check when all photos from posting was loaded to base 64 (will sort true order)
  useEffect(() => {
    if (nowPosting && tempImages && nowPosting.photos && tempImages.length === nowPosting.photos.length) {
      const sortedPhotos = []
      tempImages.map((tempImage, i) => {
        nowPosting.photos.map((serverPhotoLink, si) => {
          const filenameServ = serverPhotoLink.replace(/^.*[\\\/]/, '')
          const filenameUnsortBase64 = tempImage.file.name
          if (filenameServ === filenameUnsortBase64) {
            sortedPhotos[si] = tempImage
          }
          return true
        })
        return true
      })

      setImages(sortedPhotos)
      setIsPhotosLoading(false)
    }
  }, [nowPosting, tempImages])

  if(!nowPosting && isLoading) { return (<Spinner isVisible={true} />) }
  if(!nowPosting && !isLoading) { return (<Error404 />) }

  return (
    <Container className={`d-flex flex-column mt-4 justify-content-center ${styles.mainContainer}`} fluid="sm">
    { console.log(' ♻ Add posting page was rendered!') }

    <Row className={styles.mainRow}>
        <Col className={`border rounded-3 p-3 bg-body position-relative ${styles.mainCol}`}>
            <div className={styles.addWrapper}>
                <h2>Edit posting {nowPosting && nowPosting.title ? nowPosting.title : '-'}</h2>

                <Form onSubmit={submitHandle} >

                  <Photos images={images} setImages={setImages} isPhotosLoading={isPhotosLoading} />

                  <FloatingLabel controlId="category" label="Category" className='mb-2 mt-2'>
                    <Form.Select name='category' isInvalid={validator.vCategory} disabled={categories ? false : true} onChange={e => categoryChanged(e)} value={selectedCategory ? selectedCategory.slug : lang.formSelectCategoryPlaceholder}>
                      { categories 
                        ?
                          <>
                            <option>Select category</option>
                            {
                              categories.map((oneCat, i) => {
                                return (
                                  <option value={oneCat.slug} key={i}>{oneCat.title}</option>
                                )
                              })
                            }
                          </>
                        :
                          <option>Loading...</option>
                      }
                    </Form.Select>
                  </FloatingLabel>

                  <FloatingLabel controlId="subcategory" label="Subcategory" className='mb-2'>
                    <Form.Select name='subcategory' isInvalid={validator.vSubcategory} disabled={selectedCategory ? false : true} onChange={e => subcategoryChanged(e)} value={selectedSubcategory ? selectedSubcategory.slug : lang.formSelectSubcategoryPlaceholder} >
                      { categories
                        ?
                          selectedCategory
                          ?
                            <>
                            <option>{lang.formSelectSubcategoryPlaceholder}</option>
                            { selectedCategory.subcategories 
                              &&
                                selectedCategory.subcategories.map((oneSubcat, i) => {
                                  return (
                                    <option value={oneSubcat.slug} key={i}>{oneSubcat.title}</option>
                                  )
                                })
                            }
                            </>
                          :
                            <option>Select category first</option>
                        :
                        <option>Loading...</option>
                      }
                      {selectedCategory && selectedCategory.title}
                    </Form.Select>
                  </FloatingLabel>

                  { (selectedSubcategory && selectedSubcategory.filters) &&
                    selectedSubcategory.filters.map((oneFilter, i) => {
                      return (
                        <FloatingLabel controlId={`filter_${oneFilter.slug}`} key={i} label={oneFilter.title} className='mb-2'>
                          <Form.Select onChange={e => filtersChanged(e, oneFilter.slug)} name={`filters[${oneFilter.slug}]`} isInvalid={validator.vFilters[oneFilter.slug] ? true : false} value={selectedFilters && selectedFilters[oneFilter.slug] ? selectedFilters[oneFilter.slug] : ''}>
                          <option>{lang.formSelectAnyFilterPlaceholder}</option>
                            {
                              oneFilter.options.map((oneOption, ii) => {
                                return <option key={ii} value={oneOption.slug}>{oneOption.title}</option>
                              })
                            }
                          </Form.Select>
                        </FloatingLabel>
                      )
                    })
                  }

                  { (selectedSubcategory && !selectedSubcategory.noTitle) &&
                    <FloatingLabel controlId="title" label="Title" className='mb-2'>
                      <Form.Control required name='title' type='text' minLength={3} placeholder='Title' maxLength={150} onChange={e => setTitle(e.target.value)} value={title ? title : ''} />
                    </FloatingLabel>
                  }

                  <InputGroup className="mb-2">
                    <InputGroup.Text className={styles.currency}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                      </svg>
                    </InputGroup.Text>
                    <Form.Control className={styles.currencyFiled} name='price' id='price' required type='number' placeholder='Price' min={0} onChange={e => setPrice(e.target.value)} value={price !== null ? price : ''} />
                  </InputGroup>

                  <FloatingLabel controlId="description" label="Description" className='mb-2'>
                    <Form.Control required name='description' as="textarea" placeholder="Description" style={{ height: '120px' }} onChange={e => setDescription(e.target.value)} value={description ? description : ''}/>
                  </FloatingLabel>

                  <FloatingLabel controlId="location" label="Location" className='mb-2'>
                    <Form.Select name='location' onChange={e => setLocation(e.target.value !== lang.formSelectLocationPlaceholder ? e.target.value : null)} disabled={locations ? false : true} value={location ? location : ''}>
                      { locations ?
                        <>
                          <option>{lang.formSelectLocationPlaceholder}</option>
                          {
                            locations.map((oneLoc, i) => {
                              return (
                                <option value={oneLoc.slug} key={i}>{oneLoc.title}</option>
                              )
                            })
                          }
                        </>
                      :
                        <option>Loading...</option>
                      }
                    </Form.Select>
                  </FloatingLabel>

                  <input type="hidden" name="id" value={ nowPosting._id } />

                  <div className={styles.buttons}>
                    <Button type="submit" disabled={isPhotosLoading ? true : false}>Save</Button>
                    <Link to={searchParams.get("back") && searchParams.get("back") === 'archive' ? '/archive' : '/'}>
                      <Button variant='danger'>Cancel</Button>
                    </Link>
                  </div>

                  </Form>

                {/* <div>
                  Category: {selectedCategory ? selectedCategory.title : '-'}<br/>
                  Subcategory: {selectedSubcategory ? selectedSubcategory.title : '-'}<br/>
                  Filters: {
                    selectedFilters ? 
                      keks(selectedFilters).map((e, i) => <div key={i}>{e}</div>)
                    :
                      "-"
                  }<br/>
                  Title: {title ? title : '-'}<br/>
                  Price: {price !== null ? price : '-'}<br/>
                  Description: {description ? description : '-'}<br/>
                  Location: {location ? location : '-'}<br/>
                  Photos: {
                    images.length > 0 ? 
                      images.map((oneImg, i) => {
                        return (
                          <img src={oneImg.data_url} key={i} alt='' width={40} height={40}  />
                        )
                      })
                    :
                    "-"
                  }
                </div> */}

            </div>

            <Spinner isVisible={isLoading ? true : false} />

        </Col>
    </Row>

    <UpgradeAlert isVisible={isUpgrade} type={upgradeType} closeModal={ e => setIsUpgrade(false) } />

    </Container>
  )
}

// TODO: remove
// function keks(filters) {
//   let f = []

//   for (const key in filters) {
//     if (Object.hasOwnProperty.call(filters, key)) {
//       f.push(`${key} : ${filters[key]}`)
//     }
//   }
  
//   return f
// }