import React from 'react'

export const AvaPlaceHolder = ( {radius} ) => {
  return (
    <div className="placeholder-glow d-block rounded-circle " style={{width: radius, height: radius, minHeight: radius, minWidth: radius, backgroundColor: 'gray', overflow: 'hidden'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width={radius} height={radius} fill="#bbb" className="bi bi-person-fill" viewBox="0 0 16 16" style={{marginTop: radius / 6}}>
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        </svg>
    </div>
  )
}
