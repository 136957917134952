import { makeAutoObservable } from 'mobx'
import Cookies from 'js-cookie'
import { fetchMe } from '../api/profile/ProfileController.js'

export default class UserStore {
    constructor () {
        console.groupCollapsed(' 💼 Creating user store')
        this._user = {}
        this._accessToken = null
        this._isAuth = false
        this._alerts = []
        this._message = null
        this._refreshInProcess = false
        this._isChat = false

        this._setAccessTokenFromCookie()
        makeAutoObservable(this)

        console.log("UserStore was created!")
        console.groupEnd()
    }

    setIsChat(val) {
        this._isChat = val
    }

    setRefreshInProcess(inProcess) {
        this._refreshInProcess = inProcess
    }

    setMessage(val) {
        this._message = val
    }

    setIsAuth(val) {
        this._isAuth = val
    }

    setAccessToken(str) {
        this._accessToken = str
    }

    setUser(user) {
        this._user = user
    }

    setAva(ava) {
        this._user.profile.ava = ava
    }

    _setAlerts(alerts) {
        this._alerts = alerts
    }

    addAlert(newAlert) {
        this._alerts.push(newAlert)
    }

    removeLastAlert() {
        this._alerts.shift()
    }

    removeAlertById(id) {
        const newArr = this._alerts.filter(e => !(e.id === id))
        this._setAlerts(newArr)
    }

    get isChat() {
        return this._isChat
    }

    get refreshInProcess() {
        return this._refreshInProcess
    }

    get accessToken() {
        return this._accessToken
    }

    get message() {
        return this._message
    }

    get isAuth() {
        return this._isAuth
    }

    get user() {
        return this._user
    }

    get alerts() {
        return this._alerts
    }

    _setAccessTokenFromCookie() {
        const accessToken = Cookies.get("access_token")
        if (accessToken) { // trying to grab access token from cookie
             this._accessToken = accessToken; 
             console.log('Token was grabbed from Cookie');
             this._isAuth = true
             fetchMe()
             return true 
        } else { // if access token from cookie not eist or expired - try to refresh it
            if(Cookies.get('isa')) { // if refresh token is available through cookie
                this._isAuth = true
                console.log('trying fetch');
                fetchMe()
            }
        }
        console.log('accessToken in userStore will be null');     
        return null
    }

}