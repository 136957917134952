import React, { createContext } from 'react'
import { Footer } from './components/Footer'
import Menu from './components/Menu'
import { BrowserRouter } from 'react-router-dom'
import UserStore from './store/UserStore'
import { Router } from './Router'

import './App.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import { observer } from 'mobx-react-lite'
import { AlertZone } from './components/Alert/AlertZone'


export const userStore = new UserStore()
export const UserStoreContext = createContext(null)

const App = observer(() => {

  return (
      <UserStoreContext.Provider value={ userStore }>   
      {console.log(' ♻ App was render')}
        <BrowserRouter>
          <main>
            { userStore.isAuth ? <Menu /> : null }
            <Router />
          </main>
          { userStore.isAuth ? <Footer /> : null }
          <AlertZone />
        </BrowserRouter>
      </UserStoreContext.Provider>
  )
})

export default App;