import React from 'react'
import Nav from 'react-bootstrap/Nav'

import './style.scss'
import lang from '../../translate/en_US'

export const Footer = () => {

    const cookiesPolicyLink = `${process.env.REACT_APP_SITE_ADDR}/terms/cookies-policy`
    const privacyPolicyLink = `${process.env.REACT_APP_SITE_ADDR}/terms/privacy-policy`

  return (
    <footer className='d-flex flex-column'>

        <div className='app-version user-select-none'>v. {process.env.REACT_APP_VERSION}</div>
        
        <Nav className="justify-content-center">
            <Nav.Item>
                <Nav.Link href={cookiesPolicyLink} target='_blank' rel='noreferrer'>{lang.cookiePolicyButton}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href={privacyPolicyLink} target='_blank' rel='noreferrer'>{lang.privatePolicyButton}</Nav.Link>
            </Nav.Item>
        </Nav>

        <div className="d-flex justify-content-center cpy user-select-none">
            © { new Date().getFullYear() } GuamList
        </div>
    </footer>
  )
}
