import React, { useState } from 'react'
import { Wrapper } from './Wrapper'
import { Login } from './Login'
import { Signup } from './Signup'

import './scss/index.scss'
import lang from '../../translate/en_US'

import { Confirmation } from './Confirmation'
import { Forgot } from './Forgot'
import { Reset } from './Reset'
import { ChangePassword } from './ChangePassword'
import { useSearchParams } from 'react-router-dom'

 // type of screens wich shold be visible or not while auth
export const ScreenType = {
  login: 'login',
  signup: 'signup',
  confirmation: 'confirmation',
  forgot: 'forgot',
  resetCode: 'resetCode',
  changePassword: 'changePassword',
  updateProfile: 'updateProfile'
}

export default function Auth() {

  const [nowScreen, setNowScreen] = useState(ScreenType.login)    // what screen is visible now
  const [isLoading, setIsLoading] = useState(false)               // spinner visibility state

  // const [searchParams, setSearchParams] = useSearchParams();
  // const redirectUrl = searchParams.get("redirect_url")

  let nowScreenComponent = null

  // if (redirectUrl && redirectUrl === 'guamlist://reg') {
  //   setNowScreen(ScreenType.signup)
  // }

  let subtitle = ''
  switch (nowScreen) {
    case ScreenType.login:
      subtitle = lang.authLoginScreenTitle
      nowScreenComponent = <Login setScreen={setNowScreen} setIsLoading={setIsLoading} />
      break
    case ScreenType.signup:
      subtitle = lang.authSignupScreenTitle
      nowScreenComponent = <Signup setScreen={setNowScreen} setIsLoading={setIsLoading} />
      break
    case ScreenType.confirmation:
      subtitle = lang.authConfirmationScreenTitle
      nowScreenComponent = <Confirmation setScreen={setNowScreen} setIsLoading={setIsLoading} />
      break
    case ScreenType.forgot:
      subtitle = lang.authForgotScreenTitle
      nowScreenComponent = <Forgot setScreen={setNowScreen} setIsLoading={setIsLoading} />
      break
    case ScreenType.resetCode:
      subtitle = lang.authResetCodeScreenTitle
      nowScreenComponent = <Reset setScreen={setNowScreen} setIsLoading={setIsLoading} />
      break
    case ScreenType.changePassword:
      subtitle = lang.authChangePasswordScreenTitle
      nowScreenComponent = <ChangePassword setScreen={setNowScreen} setIsLoading={setIsLoading} />
      break
    default: break
  }

  return (
    <Wrapper isLoading={isLoading} subtitle={subtitle} setScreen={setNowScreen} screenType={nowScreen}>
      { nowScreenComponent }
    </Wrapper>
  )
}