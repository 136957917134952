import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import lang from '../../translate/en_US'
import style from './ModalAlert.module.scss'

export const ModalAlert = ({closeModal, children, header, isFooter, isHeader}) => {

  return (
    <Modal show onHide={closeModal} >
      { isHeader &&
        <Modal.Header closeButton className={style.header} >
          <Modal.Title className={style.title}>{header}</Modal.Title>
        </Modal.Header>
      }
      <Modal.Body>
        {children}
      </Modal.Body>
      { isFooter &&
        <Modal.Footer className={style.footer}>
          <Button variant="secondary" onClick={closeModal}>{lang.closeButton}</Button>
          <Button variant="primary">{lang.saveButton}</Button>
        </Modal.Footer>
      }
    </Modal>
  )
}
