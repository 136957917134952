import { ServerError } from "../ServerError"
import endpoints from "../endpoints"

// upload avatar
const fetchUploadAvatar = async (file) => {
    const result = await endpoints.uploadAvatar(file)

    if(result.data && result.data.result && result.data.result === 'SUCCESS') {
        return result.data
    }

    if (result.data) {
        console.log(result);
        const possiblyServerError = ServerError.compare(result.data)
        if (possiblyServerError) throw possiblyServerError
    }

    throw new Error('Ne ponyatno po4emu')

}

export { fetchUploadAvatar }