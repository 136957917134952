import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { UserStoreContext } from '../../App'
import { DashboardWarning } from '../../components/DashboardWarning/DashboardWarning'
import lang from '../../translate/en_US'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import styles from './favorites.module.scss'
import endpoints from '../../api/endpoints'
import { OnePost } from './OnePost'
import { useCategories } from '../../hooks/useCategories'
import { Spinner } from 'react-bootstrap'
import { Paginator } from '../../components/Paginator'

export const Favorites = observer( () => {

  const perPage = parseInt(process.env.REACT_APP_POSTINGS_PER_PAGE)
  const pagesInPaginator = 5
  const categories = useCategories()
  const { user } = useContext(UserStoreContext)
  const [loading, setLoading] = useState(true)
  const [postings, setPostings] = useState([])
  const [nowPage, setNowPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [postingWasDeleted, setPostingWasDeleted] = useState(false)

  const isShowNeedPhoneAlert = (user && user.profile && !user.profile.phone) || (user && user.profile && user.profile.phone === "") ? true : false

  useEffect(() => {

    const getPostings = async () => {
      try {
        const p = await endpoints.getMyFavorites({page: nowPage, limit: perPage, order: 'desc'})
        if (p && p.data) { 
          console.log('postings set to', p.data)
          setPostingWasDeleted(false)
          setLoading(false)
          setTotalCount(p.headers.count)
          return setPostings(p.data)
        }
        else console.log('cant load postings');
        setPostingWasDeleted(false)
        setLoading(false)
      } catch(error) {
        console.log('error whuile fetch postings for me', error);
        setPostingWasDeleted(false)
        setLoading(false)
      }
    }

    if (postingWasDeleted) { setNowPage(1) }
    getPostings()

  }, [nowPage, perPage, postingWasDeleted])

  return (
    <Container className={`d-flex flex-column mt-4 justify-content-center ${styles.mainContainer}`}>
    { console.log(' ♻ Posts page was rendered!') }

      { isShowNeedPhoneAlert &&
        <DashboardWarning>
          {lang.needPhoneNumberWarningText}<Link to='/profile'>{lang.needPhoneNumberWarningLink}</Link> 
        </DashboardWarning>
      }

      <div className={styles.postingsWrapper}>

        <div className={styles.oneListingWrapper}>
          <div className={styles.listingTitle}>
            <div className={styles.leftSide}>
              <h4>{lang.favoritesTitle} <span>({totalCount})</span></h4>
              <span>{lang.favoritesTitleDesc}</span>
            </div>
          </div>
          <div className={styles.postingsList}>
            { (!postings || postings.length === 0) 
              ?
                loading ? 
                  <div className={styles.spinner}><Spinner variant="primary" animation="border" role="status"><span className="visually-hidden">{lang.spinnerLoading}</span></Spinner></div>
                : <h2 className={styles.haveNoPosts}>{lang.haveNoPostings}</h2>
              :
                postings.map((post, i) => {
                  return (
                    <OnePost key={i} post={post} categories={categories} deleteCallback={setPostingWasDeleted} />
                  )
                })
            }
          </div>
        </div>
        
        { totalCount > perPage && <Paginator pages={totalCount / perPage} current={nowPage} onPageClick={setNowPage} pagesInPaginator={pagesInPaginator} />}

      </div>
    </Container>
  )
})