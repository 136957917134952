import React, { useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import styles from './scss/NeedProfile.module.scss'
import Spinner from '../components/Spinner'
import lang from '../translate/en_US'
import { fetchUpdateProfile } from '../api/profile/UpdateController'

export default function NeedProfile() {

    const form = useRef()
    const errorPlaceholder = useRef(null)
    const [validated, setValidated] = useState(false)
    const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state
    const [isLoading, setIsLoading] = useState(false)               // spinner visibility state
    const [nameField, setNameField] = useState('')
    const [surnameField, setSurnameField] = useState('')
    const [phoneField, setPhoneField] = useState('')

    // make UI error / clear UI error if message = null
    const error = (message = null) => {
        if (message) {
        errorPlaceholder.current.innerHTML = `<div class="alert alert-danger mt-2" role="alert">${message}</div>`
        } else {
        errorPlaceholder.current.innerHTML = ''
        }
    }

    // nedded for handlers logic
    const submitProps = {
        name: nameField,                        // name that was typed
        surname: surnameField,                  // name that was typed
        phone: phoneField,                      // name that was typed
        error: error,                           // callback for showing UI error message for user
        setValidated: setValidated,             // ????????????????????
        setIsLoading: setIsLoading,             // callback for toggle the spinner
        setBtnEnabled: setBtnEnabled            // callback for change btn disability
    }

  return (
    <Container className='d-flex mt-3 justify-content-center'>
        { console.log(' ♻ NEED NAME page was rendered!') }
        <Row className='' style={{minWidth: '300px', maxWidth: '300px'}}>
            <Col className='border rounded-3 p-3 bg-body position-relative'>
                <h2 className='d-flex pt-2 justify-content-center'>{lang.needProfileAccountWasCreated}</h2>
                <div className={styles['text-block']}>{lang.needProfileNeedNameSurname}</div>
                <Form noValidate validated={validated} onSubmit={event => {
                    event.preventDefault()
                    fetchUpdateProfile(event, submitProps)
                }} id='auth-form' className='mb-2 mt-3' ref={form} >

                    <FloatingLabel controlId="formName" label={lang.formInputNamePlaceholder} className="mb-3">
                        <Form.Control
                        type='text'
                        placeholder={lang.formInputNamePlaceholder}
                        required
                        maxLength={100}
                        onChange={event => setNameField(event.target.value)}
                        onPaste={event => event.preventDefault()}
                        name='name'
                        pattern='[a-zA-Z ]+'
                        />
                        <Form.Control.Feedback type="invalid">{lang.formInputNameValidationError}</Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel controlId="formSurname" label={lang.formInputSurnamePlaceholder} className="mb-3">
                        <Form.Control
                        type='text'
                        placeholder={lang.formInputSurnamePlaceholder}
                        required
                        maxLength={100}
                        onChange={event => setSurnameField(event.target.value)}
                        onPaste={event => event.preventDefault()}
                        name='surname'
                        pattern='[a-zA-Z ]+'
                        />
                        <Form.Control.Feedback type="invalid">{lang.formInputSurnameValidationError}</Form.Control.Feedback>
                    </FloatingLabel>

                    <div className={styles['text-block']}>{lang.needProfileEnterPhoneNumber}</div>

                    <FloatingLabel controlId="formPhone" label={lang.formInputPhonePlaceholder} className="mb-3">
                        <Form.Control
                        type='text'
                        placeholder={lang.formInputPhonePlaceholder}
                        maxLength={20}
                        onChange={event => setPhoneField(event.target.value)}
                        onPaste={event => event.preventDefault()}
                        name='phone'
                        pattern="[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}"
                        />
                        <Form.Control.Feedback type="invalid">{lang.formInputPhoneValidationError}</Form.Control.Feedback>
                    </FloatingLabel>

                    <div className='d-flex justify-content-center mt-3'>
                        <Button type='submit' variant='primary' className='d-flex ' disabled={!btnEnabled  ? 'disabled' : null} >{lang.needProfileUpdateProfileButton}</Button>
                    </div>
                </Form>
                <div id="errorAlertPlaceholder" ref={errorPlaceholder}></div>
                <Spinner isVisible={isLoading} />
            </ Col>
        </ Row>
    </Container>
  )
}
