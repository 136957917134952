import React from 'react'
import lang from '../../translate/en_US'
import './error404.scss'

export default function Error404() {
  return (
    <div className='error_page'>
      <h1 className="error_page__title">404</h1>
      <p className="error_page__message">{lang.error404Text}</p>
      <div className="error_page__text">
        <p>{lang.error404desc}</p>
      </div>
      <a href="/" className="error_page__button">{lang.error404BackToPostings}</a>
      <div className="error_page__bg layer_1"></div>
      <div className="error_page__bg layer_2"></div>
    </div>
  )
}
