import React, { useState } from 'react'
import { AvaPlaceHolder } from '../../components/Placeholder/avaPlaceholder'
import { LoadableImage } from '../../components/LoadableImage/LoadableImage'
import { useContext } from 'react'
import { UserStoreContext } from '../../App'
import { observer } from 'mobx-react-lite'
import style from './Profile.module.scss'
import { ModalAlert } from '../../components/ModalAlert/ModalAlert'
import { AddAvaForm } from './AddAvaForm'

export const Ava = observer( () => {

    const { user } = useContext(UserStoreContext)
    const [isInAvaEditMode, setIsInAvaEditMode] = useState(false)

    const avaClickHandler = () => {
        setIsInAvaEditMode(true)
    }

    return (
        <div className={`rounded-circle ${style.ava}`} >
            <LoadableImage src={user && user.profile && user.profile.ava ? user.profile.ava : '/'} width={60} height={60} className='rounded-circle' alt='avatar' >
                <AvaPlaceHolder radius={60} />
            </LoadableImage>
            <svg className={`bi bi-pencil-fill ${style.hovered}`} xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" viewBox="-15 -14 45 45" onClick={avaClickHandler}>
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
            </svg>
            { isInAvaEditMode ? 
        <ModalAlert isFooter={false} isHeader={false} closeModal={() => setIsInAvaEditMode(false)} >
          <AddAvaForm close={() => setIsInAvaEditMode(false)} />
        </ModalAlert> : null }
        </div>
    )
})
