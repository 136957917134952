import { useEffect, useState } from "react";
import endpoints from "../api/endpoints";
import { AlertModel } from "../components/Alert/AlertModel";

export function useLocations() {

    const [locations, setLocations] = useState(null)

    // load all locations from server
    useEffect(() => {
        
        const getLocations = async () => {
        try {
            const locs = await endpoints.getLocations()
            if (locs && locs.data) { console.log('locs geted:', locs.data); return setLocations(locs.data)  }
            else AlertModel.add('Server error, try again later', 'alert-danger')
            setLocations(null)
        } catch(error) {
            console.log('error whuile fetch locations', error);
            setLocations(null)
        }
        }

        getLocations()

    }, [])

    return locations

}