import React, { useRef, useState } from 'react'
import CloseButton from 'react-bootstrap/CloseButton'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import lang from '../../translate/en_US'
import { FetchUploadAva } from '../../api/uploads/fetchUploadAva'
import Spinner from '../../components/Spinner'
import style from './Profile.module.scss'

export const AddAvaForm = ({close}) => {

    const form = useRef()
    const fileUploader = useRef()
    const errorPlaceholder = useRef(null)
    const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state
    const [validated, setValidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)               // spinner visibility state
    const [selectedFile, setSelectedFile] = useState(null)

    // make UI error / clear UI error if message = null
    const error = (message = null) => {
        if (message) {
        errorPlaceholder.current.innerHTML = `<div class="alert alert-danger mt-2" role="alert">${message}</div>`
        } else {
        errorPlaceholder.current.innerHTML = ''
        }
    }

    const submitProps = {
        fileUploader: fileUploader.current,
        error: error,                           // callback for showing UI error message for user
        setValidated: setValidated,             // ????????????????????
        setIsLoading: setIsLoading,             // callback for toggle the spinner
        setBtnEnabled: setBtnEnabled,           // callback for change btn disability
        closeDialog: close
      }

    return (
        <div>
            <CloseButton className='position-absolute' onClick={close} style={{right: '15px', top: '15px'}} />
            <h2 className='d-flex pt-2 justify-content-center mb-3'>{lang.profileSetAvaModalHeader}</h2>
            <Form noValidate validated={validated} onSubmit={event => { FetchUploadAva(event, submitProps)}} id='auth-form' className='mb-2 mt-3' ref={form} >
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" ref={fileUploader} required className='m-auto mt-4 avatar' style={{maxWidth: '300px'}} onInput={(e) => setSelectedFile(e.target.value)} />
                <div className={style.limits}>{lang.profileAvaMaxSizeAndExt}</div>
            </Form.Group>
            <div className='d-flex justify-content-center mt-3'>
                <Button type='submit' variant='primary' className='d-flex ' disabled={!btnEnabled  ? 'disabled' : null} >{lang.profileSetAvaModalSaveButton}</Button>
            </div>
            </Form>
            <div id="errorAlertPlaceholder" ref={errorPlaceholder}></div>
            <Spinner isVisible={isLoading} />
        </div>
    )
}
