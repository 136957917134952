import React from 'react'
import { ChangePasswordForm } from './form/ChangePasswordForm'

export const ChangePassword = ({setScreen, setIsLoading}) => {
  return (
    <>
        <ChangePasswordForm setScreen={setScreen} setIsLoading={setIsLoading} />
    </>
  )
}
