class ServerError {
    constructor(message, code, description) {
        this.message = message
        this.code = code
        this.description = description
    }

    static answers = {
        SERVER_OFFLINE: new ServerError("Server is offline", 999, "Server is offline, try again later"), // +
        DB_OFFLINE: new ServerError("Database is offline", 445, "Database is offline, try again later"), // +
        REFRESH_TOKEN_REVOKED: new ServerError("Refresh token was revoked!", 81, "Refresh token was revoked!"), 
        USER_NEED_NAME: new ServerError("User need Name and Surname!", 44, "Have no name and surname in db!"),
        NEED_EMAIL_FIELD: new ServerError("Email field is required", 60, "Email field is required"),  // +
        NEED_REFRESH_TOKEN: new ServerError("Refresh token is required", 63, "Refresh token is required"), // +
        USER_NOT_FOUND: new ServerError("Account is not found!", 40, "Account is not found!"), // +
        PASSWORD_CANT_BE_RESET: new ServerError("Password can't be reset, try to resend code!", 34, "Password can't be reset, try to resend code!"), // +
        WRONG_PASSWORD_RESET_CODE: new ServerError("Wrong password reset code!", 35, "Wrong password reset code! Resend it!"), // +
        USER_FROM_FACEBOOK: new ServerError("Your account is connected with Facebook", 655, "Use Facebook button to log in"), // +
        HAVE_NO_USER_INFO_FROM_FACEBOOK: new ServerError("We need access to your email address", 653, "We need access to your email address"),

        FILE_TOO_LARGE: new ServerError("File size is too large", 821, 'File size is too large'), // +
        PROVIDE_FILE_FOR_UPLOAD: new ServerError("Provide file for upload", 822, 'Provide file for upload'), // +
        UPLOADING_UNEXPECTED_ERROR: new ServerError("Unexpected error while uploading", 823, 'Unexpected error while uploading'),  // +
        UPLOADING_NOT_SUPPORTED_FORMAT: new ServerError("This file format is not supported", 824, 'This file format is not supported'),  // +

    }

    // will comare any object, is can be a error message
    static compare = (obj) => {
        if(obj && obj.message && obj.code && obj.description) {
            for (var key in ServerError.answers) {
                if (ServerError.answers[key].code === obj.code) {
                    return ServerError.answers[key]
                }
            }
            return null
        }
        return null
    }

    // is obj is expected Error from server
    static isServerError = (obj) => {
        if(obj && obj.message && obj.code && obj.description) return new ServerError(obj.message, obj.code, obj.description)
        else return false
    }
}

export { ServerError }