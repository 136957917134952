const base = {

    // User menu
    userMenuProfileButton: "Profile", // +
    userMenuFavoritesButton: "Favorites", // +
    userMenuMessagesButton: "Messages", // +
    userMenuLogoutButton: "Log out", // +
    userMenuLogoutButtonInProcess: "(in process)", // +

    // Menu
    menuPosts: "My postings", // +
    menuArchive: "Archive", // +

    // Warnings
    needPhoneNumberWarningText: "If you want people to call/text you regarding your postings, please indicate phone number in ", // +
    needPhoneNumberWarningLink: "profile", // +

    // Need profile screen
    needProfileAccountWasCreated: "Your account was created successfully!", // +
    needProfileNeedNameSurname: "Buyers and sellers trust real profiles. Please enter your first and last name to use our platform.", // +
    needProfileEnterPhoneNumber: "If you want people to call/text you regarding your postings, please enter your phone number bellow", // +
    needProfileUpdateProfileButton: "Update profile", // +

    // Profile page
    profilePageName: "Name:", // +
    profilePageSurname: "Surname:", // +
    profilePageEmail: "Email:", // +
    profilePagePhone: "Phone:", // +
    profileSetPhoneButton: "add phone number",  // +
    profileSetPhoneModalHeader: "Please enter your phone number:",  // +
    profileSetPhoneModalSaveButton: "Save phone number",  // +
    profileSetAvaModalHeader: "Please select image for profile picture:",  // +
    profileSetAvaModalSaveButton: "Update profile picture",  // +
    profileProfileWasUpdatedAlert: "Profile was updated!",  // +
    profileAvaMaxSizeAndExt: `jpg / png (up to ${parseInt(process.env.REACT_APP_MAX_UPLOADING_AVATAR_SIZE) / 1024 / 1024}MB)`,

    // Auth screen
    authLoginScreenTitle: "Log in to your account",  // +
    authSignupScreenTitle: "Sign up to buy and sell with ease",  // +
    authConfirmationScreenTitle: "Please confirm your email address", // +
    authForgotScreenTitle: "Reset password",  // +
    authResetCodeScreenTitle: "Enter reset code from email", // +
    authChangePasswordScreenTitle: "Enter new password", // +
    authOrLoginWith: "or log in with",  // +
    authSignupScreenAgreementText: "By signing up, you agree to our ",  // +
    authSignupScreenAgreementTerms: "Terms",  // +
    authSignupScreenAgreementDataPolicy: "Privacy Policy",  // +
    authSignupScreenAgreementAnd: " and ",  // + 
    authSignupScreenAgreementCookiePolicy: "Cookies Policy",  // +
    authForgotPasswordLink: "Forgot password?",  // +
    authResendCodeButton: "Resend confirmation code", // +
    authResendCodeAfterButton: (sec) => `Resend code in ${sec} sec`, // +
    authResendCodeBeforeText1: "We sent confirmation email to ", // +
    authResendCodeBeforeText2: ", please click on the link in the email, if you don't receive the email, ", // +
    authResendCodeBeforeText3: "check spam folder", // +
    authResendCodeBeforeText4: " or resend the code", // +
    authResendCodeAfterText1: "We resent confirmation code to ", // +
    authResendCodeAfterText2: ", check you spam folder or try again", // +
    authLoginThroughFacebook: "Your account is connected with Facebook, use Facebook button below", // +
    authServerNotResponded: "Server doesn't respond, please try again later", // +
    authAccountExistLogin: "Account already exists, please <a href="/">log in</a>", // +
    authResetPasswordMustBeSame: "Passwords must be the same", // +
    authFacebookNeedEmail: "We need access to your email address", // +

    // Forms
    formInputNamePlaceholder: "Name",  // +
    formInputNameValidationError: "Please enter valid name",  // +
    formInputSurnamePlaceholder: "Surname",  // +
    formInputSurnameValidationError: "Please enter valid surname",  // +
    formInputPhonePlaceholder: "Phone number", // +
    formInputPhoneValidationError: "Please enter valid phone number",  // +
    formInputEmailPlaceholder: "Email", // +
    formInputEmailValidationError: "Please enter valid email address",  // +
    formInputPasswordPlaceholder: "Password",  // +
    formInputNewPasswordPlaceholder: "New password",  // +
    formInputRepeatPasswordPlaceholder: "Repeat password",  // +
    formInputPasswordValidationError: "Password must be 8-16 symbols with at least one number and one special character (@!:)",  // +
    formInputPasswordNeedError: "Please enter password",  // +
    formInputCodePlaceholder: "Code",  // +
    formInputCodeValidationError: "Please enter valid code", // +

    // Postings
    formSelectCategoryPlaceholder: "Select category",
    formSelectSubcategoryPlaceholder: "Select subcategory",
    formSelectAnyFilterPlaceholder: "Select...",
    formSelectLocationPlaceholder: "Select location",
    addNewPostingBtn: "Add new posting",
    
    formBackToLogin: "Log in?",   // +
    formBackToSignUp: "Create account", // +
    formResendCodeLink: "Resend code", // +

    formConfirmButton: "Confirm", // +
    formLoginButton: "Log in",  // +
    formSignupButton: "Create account",  // +
    formSendResetCodeButton: "Send reset code",  // +
    formSetNewPasswordButton: "Save new password", // +

    publishedTitle: "Published", // +
    publishedTitleDesc: "See all your active postings here. They are published on GuamList and can be viewed by everyone. People interested in these postings will contact you shortly", // +
    archivedTitle: "Archived", // +
    archivedTitleDesc: "See your archived postings here. They are expired and no one can see them now. To help people see them again, click \"relist\" button next to posting", // +
    favoritesTitle: "Favorites", // +
    favoritesTitleDesc: "See your favorite postings here. To tag a posting as \"favorite\", click on the heart icon on a posting. Click the broken heart icon to delete a posting from this list.", // +
    freePostingText: "Free", // +
    

    // Footer
    cookiePolicyButton: "Cookies Policy", // +
    privatePolicyButton: "Private Policy", // +

    // Some kind of errors
    haveNoPostings: "Have no postings",
    cantLoadPostings: "Can't load postings",

    // Other
    spinnerLoading: "Loading...", // +
    error404Text: "Sorry, this page does not exist :(", // +
    error404BackToPostings: "Back to postings", // +
    error404desc: "You can go back to your account and sell more things", // +
    cancelButton: "Cancel", // +
    closeButton: "Close", // +
    saveButton: "Save", // +

}

export default base