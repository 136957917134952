import React from 'react'
import { FacebookLoginButton } from './FacebookLoginButton'

export const Socials = () => {
  return (
    <div className='vstack gap-2 pt-2'>
        <FacebookLoginButton />
    </div>
  )
}
