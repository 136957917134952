import { fetchUploadAvatar } from "./fetchUploadAvatar"
import { handleUpdateProfile } from "../profile/UpdateController"
import { ServerError } from "../ServerError"
import { AlertModel } from "../../components/Alert/AlertModel"

// submint button handler when uploading ava
const FetchUploadAva = async (event, props) => {
    console.log(' 🙎‍♂️ Updating ava ASYNC')
    event.preventDefault()
    event.stopPropagation()
    props.error()
    props.setValidated(true)

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      document.querySelectorAll( ":invalid" )[1].focus();
      return
    }

    props.setIsLoading(true)
    props.setBtnEnabled(false)
    handleUpdloadAva(props)
}

const handleUpdloadAva = async (props) => {
    console.log('GO UPLOAD AVA');
    try {
        // document.querySelector('input.avatar').files[0],
        // props.fileUploader.files[0]
        const formData = new FormData()
        formData.append("avatar", props.fileUploader.files[0])
        const fetchedData = await fetchUploadAvatar(formData)
        console.log(' 🙎‍♂️ date getted!')
        console.log(fetchedData)

        props.ava = process.env.REACT_APP_SERVER_ADDR + '/' + fetchedData.path
        console.log('Will set ava for profile: ', props.ava);
        // userStore.setAva('')
        // userStore.setAva(props.ava)
        handleUpdateProfile(props)
        console.log(' 🙎‍♂️😀 user AVA was uploaded, will call update ava!!!')
 
    } catch (error) {
        console.log(' 🙎‍♂️ ERROR while uploading AVA')
        console.log(error)

        props.setIsLoading(false)
        props.setBtnEnabled(true)
        if (props.closeDialog) props.closeDialog()

        const possibleServerError = ServerError.compare(error.response)
        if (possibleServerError) AlertModel.add(possibleServerError.description, 'alert-danger')
        if (error instanceof ServerError) AlertModel.add(error.description, 'alert-danger')
        
    }
}

export { FetchUploadAva }