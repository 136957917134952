import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { CustomNavLink } from './CustomNavLink'
import { CustomDropDown } from './CustomDropDown'
import './style.scss'
import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../../store/breakpoints.js'
import lang from '../../translate/en_US'
import { ReactComponent as LogoFull } from '../../images/logo.svg';
import { MenuDropDown } from './MenuDropDown'


export default function Menu() {

  const isMobile = useMediaQuery({ query: breakpoints.mobile })
  const [isAvaOpen, setIsAvaOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const setMenuOpen = (isOpen) => {
    if (isOpen) { setIsAvaOpen(false); setIsMenuOpen(true) }
    else {setIsMenuOpen(false)}
  }

  const setAvaOpen = (isOpen) => {
    if (isOpen) { setIsAvaOpen(true); setIsMenuOpen(false) }
    else {setIsAvaOpen(false)}
  }

  return (
    <div className='backgroundMenuSafe'>
      <Navbar bg="warning" variant="light" className='header-menu'>

        { !isMobile && 
          <Navbar.Brand>
              <div className='logoLink' onClick={(e) => {window.location = process.env.REACT_APP_SITE_ADDR} } >
                <LogoFull width={150} height={30} className='menuFullLogo' />
              </div> 
          </Navbar.Brand>
        }

        { !isMobile &&
          <Nav className="">
            <CustomNavLink to='/'>{lang.menuPosts}</CustomNavLink>
            <CustomNavLink to='/archive'>{lang.menuArchive}</CustomNavLink>
          </Nav>
        }

        { isMobile && <MenuDropDown isOpen={isMenuOpen} setIsOpen={setMenuOpen} /> }
        { isMobile && <div className='mobileLogoA' onClick={(e) => {window.location = process.env.REACT_APP_SITE_ADDR} }><LogoFull width={150} height={30} className='menuFullLogo' /></div> }

        <CustomDropDown isOpen={isAvaOpen} setIsOpen={setAvaOpen} />
      </Navbar>
    </div>
  )
}
