import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Auth from '../components/Auth'

export const Confirmed = () => {

    const navigate = useNavigate()

    const ticker = async () => {
        console.log('tick');
        if (Cookies.get('isa')) {
            console.log('Here is Cookies!');
            navigate('/', { replace: true })
            navigate(0)
        } else {
            setTimeout(ticker, 1000)
        }
    }

    // start watching for auto-login
    useEffect(() => {
        ticker()
    })

    return (
        <Auth />
    )
}
