import { userStore } from "../../../../App"
import { ServerError } from "../../../ServerError"
import endpoints from "../../endpoints"

// login or signup through Facebook
const fetchFacebookLoginOrSignup = async (fbAnswer) => {
    const result = await endpoints.facebook(fbAnswer)
    const possiblyServerError = ServerError.compare(fbAnswer)
    if (possiblyServerError) return possiblyServerError
    if(result.data && result.data.access_token) userStore.setAccessToken(result.data.access_token)
    userStore.setIsAuth(true)
    return result.data
}

export { fetchFacebookLoginOrSignup }