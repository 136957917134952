import React, { useContext, useState } from 'react'
import { LoadableImage } from '../../../components/LoadableImage/LoadableImage';
import styles from "./newMessage.module.scss";
import { getPrevUrlForPostingPhoto } from '../../../helpers/functions'
import { Form, Spinner } from 'react-bootstrap';
import endpoints from '../../../api/endpoints';
import { UserStoreContext } from '../../../App';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';

export const NewMessageScreen = observer( ({ newChatInfo, reloadChats }) => {

    const { user } = useContext(UserStoreContext)
    const [chatInput, setChatInput] = useState('')
    const [sending, setSending] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const clickHandler = async (e) => {
        e.preventDefault()
        if (chatInput.length > 0) {
            setSending(true)
            // upload message to server and wait for approve
            await endpoints.addChat({posting: newChatInfo._id, initMessage: chatInput, sender: user._id})
                .then(data => {
                    console.log('answer with initial chatting: ', data);
                    setChatInput('')
                    setSending(false)
                    if (data && data.data && data.data._id) { // if all ok and chat with new message created
                        searchParams.delete('p')
                        setSearchParams(searchParams)
                        reloadChats(data.data.chat)
                    }
                }) 
                .catch(error => {
                    setSending(false)
                    console.log('some error while add new message:', error);
                })
        }
    }

    return (
        <div className={styles.newMessageScreen}>
            <div className={styles.startText}>New message about</div>
            <div className={styles.avaWrapper}>
                {(newChatInfo.photos && newChatInfo.photos[0]) && 
                    <LoadableImage className={styles.ava} src={ getPrevUrlForPostingPhoto(newChatInfo.photos[0]) } width={50} height={50}>
                        <div className={styles.avaPlaceholder}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                            </svg>
                        </div>
                    </LoadableImage>
                }
                {(newChatInfo.photos && !newChatInfo.photos[0]) && 
                    <div className={styles.avaPlaceholder}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16">
                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                        </svg>
                    </div>
                }
            </div>
            <div className={styles.title}>{newChatInfo.title}</div>
            <div className={styles.price}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                </svg>
                <span>{newChatInfo.price}</span>
            </div>
            <div className={styles.howTo}>You can start a new chat by type a message</div>
            <Form className={styles.sendArea} onSubmit={ e => { if(!sending) { clickHandler(e); } else e.preventDefault() }}>
                <Form.Control autoFocus type='text' value={chatInput} onChange = {e => setChatInput(e.target.value)} className={styles.input} />
            
                <div onClick={ e => { if(!sending) { clickHandler(e); } }} className={[styles.sendButton, (chatInput.length <= 0 || sending) ? styles.noTextInput : undefined].join(' ')}>
                    { !sending ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                        </svg>
                        :
                        <Spinner animation='border' size='sm' className={styles.senderSpinner} />
                    }
                </div>
            </Form>
        </div>
    )
})
