import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { userStore } from '../../App'
import { handleResend, handleWatch } from './handler'
import lang from '../../translate/en_US'

export const Confirmation = ({setScreen, setIsLoading }) => {

  const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state
  const [wasResend, setWasResend] = useState(false)    // is was clicked resend button

  const [nowTick, setNowTick] = useState(0)            // button timeout value
  let intervalBtn = null                               // interval for buttom timeout

  const email = userStore.user && userStore.user.email ? userStore.user.email : ''
  const beforeResend = <div>{lang.authResendCodeBeforeText1}<strong>{email}</strong>{lang.authResendCodeBeforeText2}<strong>{lang.authResendCodeBeforeText3}</strong>{lang.authResendCodeBeforeText4}</div>
  const afterResend = <div>{lang.authResendCodeAfterText1}<strong>{email}</strong>{lang.authResendCodeAfterText2}</div>

  // activate button waiting mode
  const pleaseWait = (howLong) => {
    setWasResend(true)
    if (intervalBtn) clearInterval(intervalBtn)
    let i = howLong
    intervalBtn = setInterval(() => {
      if (i > 0) {
        setNowTick(i)
        i--
      } else {
        setBtnEnabled(true)
        setNowTick(0)
        clearInterval(intervalBtn)
      }
    }, 1000);
  }

  // start watching for auto-login
  useEffect(() => {
    console.log('Use effect run handleWatch()');
    handleWatch()
  }, [])

  // nedded for handlers logic
  const resendProps = {
    setScreen: setScreen,             // callback for change visible screen
    setBtnEnabled: setBtnEnabled,     // callback for change resend button enable state
    pleaseWait: pleaseWait,           // set timeout for next resend call
    setIsLoading: setIsLoading,       // callback for toggle the spinner
  }

  return (
    <>
      {!wasResend ? beforeResend : afterResend}
      <Button disabled={!btnEnabled  ? 'disabled' : null} type='button' variant='primary' className='d-flex mt-3 mb-2 m-auto' onClick={ event => handleResend(event, resendProps) }>
        {nowTick > 0 ? lang.authResendCodeAfterButton(nowTick) : lang.authResendCodeButton}
      </Button>
    </>
  )
}
