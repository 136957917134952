import React, { useEffect, useState } from 'react'
import { userStore } from '../../App';
import styles from './Alert.module.scss'

export const OneAlert = ({message, type, id}) => {

    const [isShowed, setIsShowed] = useState(false)
    const [wasHide, setWasHide] = useState(false)
    const [myId] = useState(id)

    const hideMe = () => {
        setIsShowed(false)
        setWasHide(true)
    }

    const removeMe = (id) => {
        userStore.removeAlertById(id)
    }

    useEffect(() => {
        setIsShowed(true)
        setTimeout(hideMe, 4000)
    }, [])

    useEffect(() => {
        if (wasHide) {
            setTimeout(removeMe, 500, myId)
        }
    }, [wasHide, myId])

    const cn = ['alert', type, (isShowed ? styles.showed : null)]

  return (
    <div className={cn.join(' ')} role="alert">
        {message}
    </div>
  )
}
