import { useEffect, useState } from "react";
import endpoints from "../api/endpoints";
import { AlertModel } from "../components/Alert/AlertModel";

export function useCategories() {

    const [categories, setCategories] = useState(null)

    // load all categories from server
    useEffect(() => {
        
        const getCategories = async () => {
        try {
            const cats = await endpoints.getCategories()
            if (cats && cats.data) { console.log('cats geted:', cats.data); return setCategories(cats.data)  }
            else AlertModel.add('Server error, try again later', 'alert-danger')
            setCategories(null)
        } catch(error) {
            console.log('error whuile fetch categories', error);
            setCategories(null)
        }
        }

        getCategories()

    }, [])

    return categories

}