import axios from './index'

const endpoints = {
    login: async (data) => await axios.post("/auth/login", data, { withCredentials: true }),
    refresh: async () => await axios.post("/auth/refresh", null, { withCredentials: true }),
    signup: async (data) => await axios.post('/signup', data, { withCredentials: true }),
    activation: async (data) => await axios.post('/activation', data, { withCredentials: true }),
    forgot: async (data) => await axios.post('/forgot', data, { withCredentials: true }),

    facebook: async (data) => await axios.post('/auth/facebook', data, { withCredentials: true }),
}

export default endpoints