import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import endpoints from '../../../../api/endpoints'
import { UserStoreContext } from '../../../../App'
import { LoadableImage } from '../../../../components/LoadableImage/LoadableImage'
import { createdDateTime } from '../../../../helpers/functions'
import styles from './message.module.scss'

export const Message = observer( ({ message, noScrollNeeded }) => {

    const { user } = useContext(UserStoreContext)
    const [isDelivered, setIsDelivered] = useState(message.delivered)
    const [isReaded, setIsReaded] = useState(message.readed)
    const messagesEndRef = useRef(null)

    useEffect(() => {
        if (message) setIsDelivered(message.delivered)
        if (message) setIsReaded(message.readed)
        if (messagesEndRef && messagesEndRef.current && !noScrollNeeded) {
            messagesEndRef.current.scrollIntoView(false)
        }

    }, [message, messagesEndRef, noScrollNeeded])

    useEffect(() => {

        const setMessageToReaded = async () => {
            await endpoints.setMessageReaded({ message: message._id })
                .then(data => {
                    if (data.data && data.data._id) {
                        console.log('Message set to readed', data.data);
                        setIsReaded(true)
                    }
                })
                .catch(err => {
                    console.log('error while set message to readed:', err);
                })
        }

        if(message && !message.readed && message.sender && message.sender._id !== user._id) {
            setMessageToReaded()
        }
    }, [message, user])

    if (!message) return '...'

    return (
        <div className={[styles.oneMessageWrapper, message.sender._id !== user._id ? styles.responderSide : undefined].join(' ')} ref={messagesEndRef} >
            <div className={[styles.oneMessage, message.sender._id !== user._id ? styles.responderSide : undefined].join(' ')}>

                <div className={styles.avaWrapper}>
                    { (message.sender.profile && message.sender.profile.ava) ?
                            <LoadableImage src={message.sender.profile.ava} width={30} height={30} className={styles.ava} onClick={e => { window.open(process.env.REACT_APP_SITE_ADDR + '/user/' + String(message.sender._id), '_blank'); }}>
                                <Spinner animation='border' size='sm' />
                            </LoadableImage>
                        :
                        <div className={styles.avaPlaceholder} onClick={e => { window.open(process.env.REACT_APP_SITE_ADDR + '/user/' + String(message.sender._id), '_blank'); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-video-off" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518l.605.847zM1.428 4.18A.999.999 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634l.58.814zM15 11.73l-3.5-1.555v-4.35L15 4.269v7.462zm-4.407 3.56-10-14 .814-.58 10 14-.814.58z"/>
                            </svg>
                        </div>
                    }
                </div>
                
                <div className={styles.textWrapper}>
                    {message.body}
                </div>

            </div>

            { (isReaded === false && message.sender._id !== user._id) &&
                <div className={styles.haveNewMessage}>
                    <div className={styles.dateTime}>{createdDateTime(message.createdAt)}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                    </svg>
                </div> 
            }

            { (isReaded === true && message.sender._id !== user._id) &&
                <div className={styles.newMessageReaded}>
                    <div className={styles.dateTime}>{createdDateTime(message.createdAt)}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                    </svg>
                </div> 
            }

            { (isReaded === false && isDelivered === false && message.sender._id === user._id) &&
                    <div className={styles.delivered}>
                    <div className={styles.dateTime}>{createdDateTime(message.createdAt)}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stopwatch" viewBox="0 0 16 16">
                        <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
                        <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
                    </svg>
                    </div>
            }     

            { (isReaded === false && isDelivered === true && message.sender._id === user._id) &&
                    <div className={styles.delivered}>
                    <div className={styles.dateTime}>{createdDateTime(message.createdAt)}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                    </div> 
            }

            { (isReaded === true && isDelivered === true && message.sender._id === user._id) &&
                    <div className={styles.deliveredAndReaded}>
                    <div className={styles.dateTime}>{createdDateTime(message.createdAt)}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                        <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
                        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
                    </svg>
                    </div> 
            }
        </div>
    )
})
