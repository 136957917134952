
import axios from './auth/index'

const endpoints = {
    me: async () => await axios.get('/users/me', { withCredentials: true }),
    logout: async () => await axios.get('/users/logout', { withCredentials: true }),
    updateProfile: async (bodyJSON) => await axios.post('/users/me/update', bodyJSON, { withCredentials: true }),
    uploadAvatar: async (data) => await axios.post('/uploadAvatar', data, { withCredentials: true }),
    getMyPostings: async (data) => await axios.get('/users/me/postings', { withCredentials: true, params: data }),
    getMyFavorites: async (data) => await axios.get('/users/me/favorites', { withCredentials: true, params: data }),
    getCategories: async () => await axios.get('/categories', { withCredentials: true }),
    getLocations: async () => await axios.get('/locations', { withCredentials: true }),
    getPlan: async (planSlug) => await axios.get('/plan', { withCredentials: true, params: { slug: planSlug } }),
    addNewPosting: async (data) => await axios.post('/postings/add', data, { withCredentials: true }),
    editPosting: async (data) => await axios.post('/postings/edit', data, { withCredentials: true }),
    deletePosting: async (data) => await axios.post('/postings/delete', data, { withCredentials: true }),
    unlikePosting: async (data) => await axios.post('/postings/unlike', data, { withCredentials: true }),
    renewPosting: async (data) => await axios.post('/postings/renew', data, { withCredentials: true }),
    getOnePosting: async (id) => await axios.get('/posting/get', { withCredentials: true, params: { id: id } }),
    getAllChats: async (abortCont, params) => await axios.get('/messages/chats', { withCredentials: true, signal: abortCont ? abortCont.signal : null, params: params}),
    getMessages: async (abortCont, params) => await axios.get('/messages', { withCredentials: true, signal: abortCont ? abortCont.signal : null, params: params}),
    addMessage: async (data) => await axios.post('/messages/add', data, { withCredentials: true }),
    setMessageReaded: async (data) => await axios.post('/messages/readed', data, { withCredentials: true }),
    isMessageReaded: async (data) => await axios.post('/messages/isreaded', data, { withCredentials: true }),
    deleteChat: async (data) => await axios.post('/messages/chats/delete', data, { withCredentials: true }),
    addChat: async (data) => await axios.post('/messages/chats/add', data, { withCredentials: true }),
    isChatExist: async (postingId) => await axios.get('/messages/isChatExists', { withCredentials: true, params: { posting: postingId } }),
}

export default endpoints