import React from 'react'
import { ScreenType } from '.'
import { Forms } from './form/Forms'
import { Socials } from './Socials'
import lang from '../../translate/en_US'

export const Signup = ({setScreen, setIsLoading}) => {

    const cookiesPolicyLink = `${process.env.REACT_APP_SITE_ADDR}/terms/cookies-policy`
    const privacyPolicyLink = `${process.env.REACT_APP_SITE_ADDR}/terms/privacy-policy`
    const termsOfUseLink = `${process.env.REACT_APP_SITE_ADDR}/terms/terms-of-use`

    return (
        <>
            <Forms screenType={ScreenType.signup} setScreen={setScreen} setIsLoading={setIsLoading} />
            <div className='terms'>{lang.authSignupScreenAgreementText}<a href={termsOfUseLink} target='_blank' rel='noreferrer'>{lang.authSignupScreenAgreementTerms}</a>, <a href={privacyPolicyLink} target='_blank' rel='noreferrer'>{lang.authSignupScreenAgreementDataPolicy}</a>{lang.authSignupScreenAgreementAnd}<a href={cookiesPolicyLink} target='_blank' rel='noreferrer'>{lang.authSignupScreenAgreementCookiePolicy}</a>.</div>
            <div className='strike mt-3'><span>{lang.authOrLoginWith}</span></div>
            <Socials />
        </>
    )
}
