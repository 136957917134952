import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import endpoints from '../../../api/endpoints'
import { LoadableImage } from '../../../components/LoadableImage/LoadableImage'
import styles from './header.module.scss'

export const Header = ({ responderUser, selectedChat, setSelectedChat }) => {

    // const nameTrimLength = 20
    const itemTitleTrimLength = 30
    const [isDeletingProcess, setIsDeletingProcess] = useState(false)
    const [isChatDeleted, setIsChatDeleted] = useState(false)

    const deleteChatHandler = async () => {
        setIsDeletingProcess(true)
        endpoints.deleteChat({ chat: selectedChat._id })
            .then(data => {
                if(data.data && data.data === 'SUCCESS') {
                    console.log('chat deleted');
                    setIsChatDeleted(true)
                    setSelectedChat(null)
                } else console.log('some other error while chat deleting');
                setIsDeletingProcess(false)
            })
            .catch(error => {
                console.log('error while deleting chat', error);
                setIsDeletingProcess(false)
            })
    }

    useEffect(() => {
        setIsChatDeleted(false)
    }, [selectedChat])

    return (
        <div className={styles.header}>
            <div className={styles.avaWrapper}>
                { (responderUser && responderUser.profile && responderUser.profile.ava) ?
                    <LoadableImage className={styles.ava} src={responderUser.profile.ava} width={50} height={50} onClick={e => { window.open(process.env.REACT_APP_SITE_ADDR + '/user/' + String(responderUser._id), '_blank'); }}>
                        <div className={styles.avaPlaceholder}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                            </svg>
                        </div>
                    </LoadableImage>
                :
                    <div className={styles.avaPlaceholder} onClick={e => { window.open(process.env.REACT_APP_SITE_ADDR + '/user/' + String(responderUser._id), '_blank'); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-video-off" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518l.605.847zM1.428 4.18A.999.999 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634l.58.814zM15 11.73l-3.5-1.555v-4.35L15 4.269v7.462zm-4.407 3.56-10-14 .814-.58 10 14-.814.58z"/>
                        </svg>
                    </div>
                }
            </div>
            <div className={styles.body}>
                <div className={styles.userName}>{responderUser ? `${responderUser.name} ${responderUser.surname}` : '...'}</div>
                <div className={styles.aboutItem}>
                    <span>About: </span>
                    {selectedChat.posting ?
                        <a className={styles.aboutItem}  href={process.env.REACT_APP_SITE_ADDR + `/posting/${selectedChat.posting._id}`} target='_blank' rel="noreferrer">{ selectedChat.posting.title.length > itemTitleTrimLength ? selectedChat.posting.title.substring(0, itemTitleTrimLength) + '...' : selectedChat.posting.title }
                        </a>
                        :
                        <span>Deleted item</span>
                    }
                </div>
            </div>
            <div className={styles.rightActions}>
                { (!selectedChat.posting && !isDeletingProcess && !isChatDeleted) && <div className={styles.removeButton} onClick={deleteChatHandler}>Delete this chat</div>}
                {isDeletingProcess && <Spinner animation='border' size='sm' />}
                {(!isDeletingProcess && selectedChat.posting) && 
                    <div className={styles.price}>
                        ${selectedChat.posting.price}
                    </div> 
                }
            </div>

        </div>
    )
    }
