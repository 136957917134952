import React from 'react'
import style from './Profile.module.scss'

export const PhonePresentation = ({phone, openEdit}) => {

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        openEdit(true)
    }

    return (
        <div className='hstack gap-2'>
            <div>{phone}</div>
            <a href='/' onClick={(e) => handleClick(e)} className={style['phone-presentation-edit']}>Edit</a>
        </div>
    )
}
