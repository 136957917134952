import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { LoadableImage } from '../../../components/LoadableImage/LoadableImage';
import styles from './onePost.module.scss'
import lang from '../../../translate/en_US'
import { getPrevUrlForPostingPhoto } from '../../../helpers/functions';
import endpoints from '../../../api/endpoints';
import { AlertModel } from '../../../components/Alert/AlertModel';
import { DangerAlert } from '../../../components/DangerAlert/DangerAlert';
import { Spinner } from 'react-bootstrap';
import { UpgradeAlert } from '../../../components/UpgradeAlert/UpgradeAlert';
import { useNavigate } from 'react-router-dom'

export const OnePost = ({ post, categories, deleteCallback }) => {
    console.log(post);

    const navigation = useNavigate()
    const [withoutImage, setWithoutImage] = useState(false)
    const [errorImage, setErrorImage] = useState(false)
    const [category, setCategory] = useState('...')
    const [subcategory, setSubcategory] = useState('...')
    const [isDeleteAlertVisible, setIsDeleteAlertVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpgrade, setIsUpgrade] = useState(false)
    const [upgradeType, setUpgradeType] = useState('total')

    useEffect(() => {
        // set "no photo" pic for listing without photos
        if (post.photos && Array.isArray(post.photos) && post.photos.length < 1) {
            setWithoutImage(true)
            setErrorImage(false)
        }

        // set "error photo" pic for listing which photos not found
        if (post.photos && Array.isArray(post.photos) && post.photos.length > 0) {
            setWithoutImage(false)
            setErrorImage(false)
            var http = new XMLHttpRequest()
            http.open('HEAD', post.photos[0], false)
            http.send();
            if (http.status === 404) setErrorImage(true)
        }

    }, [post])

    useEffect(() => {
        if (post.category && categories && Array.isArray(categories)) {
            const c = categories.find(oneCat => oneCat.slug === post.category)
            if (c) {
                setCategory(c.title)
                const sc = c.subcategories.find(oneSubCat => oneSubCat.slug === post.subcategory)
                if (sc) setSubcategory(sc.title)
            }

        }
    }, [categories, post])

    const deleteHandler = async (e) => {
        setIsLoading(true)
        setIsDeleteAlertVisible(false)
        try {
            const answer = await endpoints.deletePosting({posting: post._id})
            if (answer && answer.data && answer.data === 'SUCCESS') { 
                setIsLoading(false)
                deleteCallback(true)
            } else {
                AlertModel.add('Can\'t delete posting', 'alert-danger')
                setIsLoading(false)
            }
        } catch (error) {
            console.log('error while trying delete posting', error);
            setIsLoading(false)
        }
    }

    const renewHandler = async (e) => {
        setIsLoading(true)
        try {
            const answer = await endpoints.renewPosting({posting: post._id})
            if (answer && answer.data && answer.data === 'SUCCESS') { 
                setIsLoading(false)
                deleteCallback(true)
            } else {
                AlertModel.add('Can\'t renew posting', 'alert-danger')
                setIsLoading(false)
            }
        } catch (error) {
            console.log('error while trying renew posting', error);
            if (error.code && error.code === 80802) { setUpgradeType('total'); setIsUpgrade(true) }
            else if (error.code && error.code === 80803) { setUpgradeType('real-estate'); setIsUpgrade(true) }
            else if (error.code && error.code === 80804) { setUpgradeType('vehicles'); setIsUpgrade(true) }
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.onePostWrapper} >
            <div className={styles.onePost}>

                <div className={styles.leftSide} onClick={(e) => {window.open(process.env.REACT_APP_SITE_ADDR + '/posting/' + post._id, '_blank').focus()} }>
                    { (!withoutImage && !errorImage) && 
                        <LoadableImage className={styles.image} src={getPrevUrlForPostingPhoto(post.photos[0])} width={60} height={60}>
                            <div className={styles.placeholder}><i className="bi bi-arrow-repeat"></i></div>
                        </LoadableImage>
                    }
                    { (withoutImage === true) && <div className={styles.tempImg}><div className={styles.placeholder}><i className="bi bi-image"></i></div></div> }
                    { (errorImage) && <div className={styles.tempImg}><div className={styles.placeholder}><i className="bi bi-exclamation-diamond"></i></div></div> }

                    <div className={styles.titleWrapper}>
                        <div className={styles.title}><span>{post.title}</span></div>
                        <div className={styles.categories}>
                            <span>{category} <i className="bi bi-arrow-right"></i> {subcategory}</span>
                        </div>
                        <div className={styles.price}>
                            <span>
                                {(post.price && post.price > 0) ? <i className="bi bi-currency-dollar"></i> : ''}
                                {post.price && post.price > 0 ? post.price : lang.freePostingText}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={styles.rightSide}>
                    <Button 
                        className={styles.relist} 
                        disabled={isLoading} 
                        onClick={ (e) => { e.preventDefault(); e.stopPropagation(); renewHandler(e) } }
                    >
                        { isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i className="bi bi-arrow-clockwise"></i>}
                    </Button>

                    <Button 
                        className={styles.edit} 
                        disabled={isLoading} 
                        onClick={ (e) => { e.preventDefault(); e.stopPropagation(); navigation(`/posting/edit?id=${post._id}&back=archive`) } }
                    >
                        { isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i className="bi bi-pencil"></i>}
                    </Button>

                    <Button 
                        className={styles.danger}
                        disabled={isLoading} 
                        onClick={ (e) => { e.preventDefault(); e.stopPropagation(); setIsDeleteAlertVisible(true) } }
                    >
                        { isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <i className="bi bi-trash3"></i>}
                    </Button>
                </div>

                <DangerAlert 
                    isVisible={isDeleteAlertVisible}
                    isFooter 
                    actionButtonText={'Delete'} 
                    closeModal={ (e) => { setIsDeleteAlertVisible(false)} }
                    fireAction={ deleteHandler }
                >
                    <div>Do you want to delete this posting?</div>
                    <div className={styles.undone}>This action cant be undone</div>
                </DangerAlert>

                <UpgradeAlert isVisible={isUpgrade} type={upgradeType} closeModal={ e => setIsUpgrade(false) } />
            </div>
        </div>
    )
}
