import React from 'react'
import { LoadableImage } from '../../../components/LoadableImage/LoadableImage'
import { createdDateTime, getPrevUrlForPostingPhoto } from '../../../helpers/functions'
import styles from './oneChat.module.scss'

export const OneChat = ({ chat, setChat, selectedChat, setPage }) => {

    const titleTrimLength = 18
    const msgTrimLength = 30

  return (
    <div className={[styles.oneChat, (selectedChat && chat._id === selectedChat._id) ? styles.selected : undefined].join(' ')} onClick={ e => { setChat(chat); setPage(1) } } >
        <div className={styles.avaWrapper}>
            {(chat.posting && chat.posting.photos[0]) && 
                <LoadableImage className={styles.ava} src={getPrevUrlForPostingPhoto(chat.posting.photos[0])} width={50} height={50}>
                    <div className={styles.avaPlaceholder}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16">
                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                        </svg>
                    </div>
                </LoadableImage>
            }
            {(chat.posting && !chat.posting.photos[0]) && 
                <div className={styles.avaPlaceholder}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16">
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                    </svg>
                </div>
            }
            {!chat.posting && 
                <div className={styles.avaPlaceholder}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
                </div>
            }
        </div>
        <div className={styles.body}>
            { chat.posting && <div className={styles.title}>{chat.posting.title.length > titleTrimLength ? chat.posting.title.substring(0, titleTrimLength) + '...' : chat.posting.title}</div> }
            { !chat.posting && <div className={styles.title}>Deleted item</div>}

            { chat.lastMessage && <div className={styles.lastMsg}>{ chat.lastMessage.body.length > msgTrimLength ? chat.lastMessage.body.substring(0, msgTrimLength) + '...' : chat.lastMessage.body}</div> }

            { chat.lastMessage && <div className={styles.dateTime}>{ createdDateTime(chat.lastMessage.updatedAt) }</div> }
  
        </div>

        { 
            (chat.unreadedCount) && 
                <div className={styles.haveNewMessage}>
                    { (chat.unreadedCount < 10) ? chat.unreadedCount : '..' }
                </div> 
        }
    </div>
  )
}
