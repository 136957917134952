import { userStore } from '../../App'

class AlertModel {
    constructor(message, type) {
        this.message = message
        this.type = type
        this.id = Math.floor(Math.random() * Date.now())
    }

    static add = (message, type) => {
        const alert = new AlertModel(message, type)
        userStore.addAlert(alert)
    }
}

export { AlertModel }