import React from 'react'

export const Placeholder = ( {places} ) => {
  return (
    <div className="placeholder-glow d-block" >
        { places.map((v, i) => {
            const cls=`placeholder me-2 col-${v}`
            return <span className={cls} key={i}></span>
            }) }
    </div>
  )
}
