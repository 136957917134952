import React, { useContext } from 'react'
import styles from './photos.module.scss'
import ImageUploading from 'react-images-uploading'
import Image from 'react-bootstrap/Image'
import { usePlan } from '../../../hooks/usePlan'
import { UserStoreContext } from '../../../App'
import { observer } from 'mobx-react'
import { Spinner } from 'react-bootstrap'

export const Photos = observer( ({ images, setImages, isPhotosLoading }) => {

  const { user } = useContext(UserStoreContext)
  const plan = usePlan(user.plan)
  const maxNumber = plan ? plan.limitations.maxUploadingFiles : 4
  const acceptType = ['jpg', 'png', 'HEIF', 'jpeg', 'PNG']


  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList);
    setImages(imageList);
  };

  return (
    <div className={styles.photosWrapper}>

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        maxFileSize={process.env.REACT_APP_MAX_UPLOADING_PHOTO_SIZE}
        acceptType={acceptType}
        inputProps={{name: 'photos'}}
      >
      {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <div className={styles.insideWrapper} {...dragProps}>
            <div className={styles.uploadSpace} style={{ display: imageList.length >= maxNumber ? 'none' : 'block' }} >
              <div className={styles.dragHere}>Drag photo here or <span onClick={onImageUpload}>select it on your device</span></div>
            </div>
            <div className={styles.divWithImages}>
                {imageList.map((image, index) => (
                  <div className={styles.onePhoto} key={index}>
                    <div className={styles.removeBtn} onClick={() => onImageRemove(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                      </svg>
                    </div>
                    <Image src={image.data_url} rounded width={100} height={100}  />
                  </div>
                ))}
            </div>
            {isPhotosLoading && <div className={styles.loadingPhotoSpinner}><Spinner animation='border' /></div> }
            <div className={styles.desc}>
                You can upload up to {maxNumber} photos, {process.env.REACT_APP_MAX_UPLOADING_PHOTO_SIZE / 1024 / 1024} MB, With JPG, PNG format
            </div>
            { errors && 
              <div className={styles.error}>
                  {errors.maxNumber && <span>Number of selected images exceed {maxNumber}</span>}
                  {errors.acceptType && <span>Your selected file type is not allow</span>}
                  {errors.maxFileSize && <span>Selected file size exceed {process.env.REACT_APP_MAX_UPLOADING_PHOTO_SIZE / 1024 / 1024} MB</span>}
                  {errors.resolution && <span>Selected file is not match your desired resolution</span>}
              </div>
            }
          </div>
        )}
      </ImageUploading>

      

    </div>
  )
})
