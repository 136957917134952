import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export const CustomNavLink = ({ to, children }) => {

  const loc = useLocation()
    
  return (
    <Link to={to} className={`nav-link ${loc.pathname === to ? 'active' : ''}`}>
        {children}
    </Link>
  )
}
