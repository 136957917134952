const getPrevUrlForPostingPhoto = (url) => {
    if (url) {
        let result = url.split('.').slice(0, -1).join('.')
        result += '_p.png'
        return result
    }
    return null
}

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;   
      resolve(base64data);
    }
  });
}

const urlToFile = async (url, filename, mimeType) => {
  return (fetch(url)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], filename,{type:mimeType});})
  );
}

const createdDateTime = (isoMongoDate) => {
  const event = new Date(isoMongoDate)
  const nowYear = new Date().getFullYear()
  const eventYear = event.getFullYear()
  const nowDay = new Date().getDay()
  const eventDay = event.getDay()
  const options = {hour: '2-digit', minute: '2-digit' }
  if (eventYear !== nowYear) options.year = 'numeric'
  if (eventDay !== nowDay) { 
    options.day = 'numeric'
    options.month = 'long'
    return event.toLocaleDateString('en-US', options)
  }
  return event.toLocaleTimeString('en-US', options)
}

export { getPrevUrlForPostingPhoto, getBase64FromUrl, urlToFile, createdDateTime }