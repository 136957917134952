import React, { useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import lang from '../../translate/en_US'
import Button from 'react-bootstrap/Button'
import CloseButton from 'react-bootstrap/CloseButton'
import Spinner from '../../components/Spinner'
import { fetchUpdateProfile } from '../../api/profile/UpdateController'

export const AddPhoneForm = ({close}) => {

    const form = useRef()
    const errorPlaceholder = useRef(null)
    const [phoneField, setPhoneField] = useState('')
    const [validated, setValidated] = useState(false)
    const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state
    const [isLoading, setIsLoading] = useState(false)               // spinner visibility state

    // make UI error / clear UI error if message = null
    const error = (message = null) => {
      if (message) {
      errorPlaceholder.current.innerHTML = `<div class="alert alert-danger mt-2" role="alert">${message}</div>`
      } else {
      errorPlaceholder.current.innerHTML = ''
      }
    }

    const submitProps = {
      phone: phoneField,                      // name that was typed
      error: error,                           // callback for showing UI error message for user
      setValidated: setValidated,             // ????????????????????
      setIsLoading: setIsLoading,             // callback for toggle the spinner
      setBtnEnabled: setBtnEnabled,           // callback for change btn disability
      closeDialog: close
    }

  return (
    <Form noValidate validated={validated} onSubmit={event => {
      event.preventDefault()
      fetchUpdateProfile(event, submitProps)
      }} id='auth-form' className='mb-2 mt-3' ref={form} >
        <CloseButton className='position-absolute' onClick={close} style={{right: '15px', top: '15px'}} />
        <h2 className='d-flex pt-2 justify-content-center mb-3'>{lang.profileSetPhoneModalHeader}</h2>
        <FloatingLabel controlId="formPhone" label={lang.formInputPhonePlaceholder} className='m-auto ' style={{maxWidth: '300px'}}>
            <Form.Control
            type='text'
            required
            placeholder={lang.formInputPhonePlaceholder}
            maxLength={20}
            onChange={event => setPhoneField(event.target.value)}
            onPaste={event => event.preventDefault()}
            name='phone'
            pattern="[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}"
            />
            <Form.Control.Feedback type="invalid">{lang.formInputPhoneValidationError}</Form.Control.Feedback>
        </FloatingLabel>

        <div className='d-flex justify-content-center mt-3'>
          <Button type='submit' variant='primary' className='d-flex ' disabled={!btnEnabled  ? 'disabled' : null} >{lang.profileSetPhoneModalSaveButton}</Button>
        </div>

        <div id="errorAlertPlaceholder" ref={errorPlaceholder}></div>
        <Spinner isVisible={isLoading} />
    </Form>
  )
}
