import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import lang from '../../translate/en_US'
import style from './DangerAlert.module.scss'

export const DangerAlert = ({closeModal, fireAction, children, header, isFooter, isHeader, isVisible, actionButtonText}) => {

  return (
    <Modal 
      show= {isVisible}
      onHide={ () => { closeModal() }}
      centered
      onClick={ (e) => {e.preventDefault(); e.stopPropagation(); } }
    >
      { isHeader &&
        <Modal.Header closeButton className={style.header} >
          <Modal.Title className={style.title}>{header}</Modal.Title>
        </Modal.Header>
      }
      <Modal.Body className={style.body}>
        {children}
      </Modal.Body>
      { isFooter &&
        <Modal.Footer className={style.footer}>
          <Button variant="secondary" onClick={closeModal}>{lang.cancelButton}</Button>
          <Button variant="danger" onClick={fireAction}>{actionButtonText}</Button>
        </Modal.Footer>
      }
    </Modal>
  )
}
