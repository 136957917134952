import { ScreenType } from "."
import { ServerError } from "../../api/ServerError"
import { userStore } from "../../App"
import { fetchMe } from "../../api/profile/ProfileController.js"
import { AlertModel } from "../Alert/AlertModel"
import { fetchActivation, fetchForgot, fetchLogin, fetchSignup } from './fetcher'
import lang from "../../translate/en_US"

// when login or Signup button clicked
const handleSubmit = async (event, props) => {
    event.preventDefault()
    event.stopPropagation()
    props.error()
    props.setValidated(true)

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
    //   setValidated(true)
      document.querySelectorAll( ":invalid" )[1].focus();
      return
    }
    // setValidated(true)

    props.setIsLoading(true)
    props.setBtnEnabled(false)
    if (props.screenType === ScreenType.login) handleLogin(props)
    else if (props.screenType === ScreenType.signup) handleSignup(props)
    else if (props.screenType === ScreenType.forgot) handleForgot(props)
    else if (props.screenType === ScreenType.resetCode) handleReset(props)
    else if (props.screenType === ScreenType.changePassword) handleChangePassword(props)
} 

// login handler
const handleLogin = async (props) => {
    try {
        console.log('otpravlyaem LOGIN')
        const data = await fetchLogin(props.email, props.password)
        if (data.type && data.type === 'fetchError') { errorHandler(data.message, props.error, props.setIsLoading, props.setBtnEnabled); return }
        console.log(data)
        if (data && data.access_token) {
            userStore.setAccessToken(data.access_token)
            userStore.setIsAuth(true)
            console.log('zapisal token: ')
            console.log(userStore.accessToken)
            fetchMe()
        } else if (data && data.code && data.code === 43) {
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            userStore.setUser({email: props.email, password: props.password})
            props.setScreen(ScreenType.confirmation)
        } else if(data && data.code && data.code === 655) {
            console.log('user was registred from facebook');
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            props.error(lang.authLoginThroughFacebook)
        } else {
            console.log('no data while fetch login, maybe db was down');
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            props.error(lang.authServerNotResponded)
        }
    } catch(err) {
        console.log('Some error while handle login:');
        console.log(err);
        props.setBtnEnabled(true)
        props.setIsLoading(false)
        if (err instanceof ServerError) {
            props.error(err.description)
        }   
    }
}

// signup handler
const handleSignup = async (props) => {
    try {
        console.log('otpravlyaem REGISTRATION')
        const data = await fetchSignup(props.email, props.password)
        if (data.type && data.type === 'fetchError') { errorHandler(data.message, props.error, props.setIsLoading, props.setBtnEnabled); return }
        console.log(data)
        if (data && data.code && data.code === 20) { // show check email view
            props.setIsLoading(false)
            props.setBtnEnabled(true)
            userStore.setUser({email: props.email, password: props.password})
            props.setScreen(ScreenType.confirmation)
        } else if (data && data.code && data.code === 30) {
            props.setIsLoading(false)
            props.setBtnEnabled(true)
            props.error(lang.authAccountExistLogin)
        } else {
            console.log('no data while fetch registration, maybe db was down');
            props.setIsLoading(false)
            props.setBtnEnabled(true)
            props.error(lang.authServerNotResponded)
        }
        props.setIsLoading(false)
        props.setBtnEnabled(true)
    } catch(err) {
        console.log('Some error while handle signup:');
        console.log(err);
        if (err instanceof ServerError) {
            props.error(err.description)
        }
        props.setIsLoading(false)
        props.setBtnEnabled(true)
    }
}

// handle resend confirmation code button
const handleResend = async (event, props) => {
    console.log(userStore.user.email)
    if (userStore.user && userStore.user.email && userStore.user.email !== "") {
        try {
            props.setBtnEnabled(false)
            props.setIsLoading(true)
            const result = await fetchActivation(userStore.user.email)
            console.log(result);
            const possibleServerError = ServerError.compare(result)
            if (possibleServerError || (result && result instanceof ServerError)) {
                AlertModel.add(result.description, 'alert-danger')
                props.setIsLoading(false)
                props.pleaseWait(120)
                return
            }
            console.log('IF VSE OK');
            props.setIsLoading(false)
            props.pleaseWait(120)
        } catch(e) {
            const possibleServerError = ServerError.compare(e)
            if (possibleServerError || (e instanceof ServerError)) {
                AlertModel.add(e.description, 'alert-danger')
                props.setIsLoading(false)
                props.pleaseWait(120)
            }
        }
    } else {
        userStore.setUser({})
        props.setScreen(ScreenType.login)
    }
}

// handle a auto-login watch
const handleWatch = async () => {
    if (userStore.user && userStore.user.email && userStore.user.email !== ""
        && userStore.user && userStore.user.password && userStore.user.password !== "") {
        console.log(`handle Watch start with email: ${userStore.user.email}, password: ${userStore.user.password}`);
        try {
            const data = await fetchLogin(userStore.user.email, userStore.user.password)
            if (data && data.code && data.code === 43) {
                console.log('still no confirmed');
                setTimeout(handleWatch, 5000, userStore.user.email, userStore.user.password)
                return
            } else if (data && data.access_token) {
                userStore.setAccessToken(data.access_token)
                userStore.setUser({})
                userStore.setIsAuth(true)
                console.log('zapisal token: ')
                console.log(userStore.accessToken)
                fetchMe()
                return
            } 
            else {
                const possibleServerError = ServerError.compare(data)
                if (possibleServerError) {
                    AlertModel.add(possibleServerError.description, 'alert-danger')
                    console.log('still no confirmed + maybe database dead');
                    setTimeout(handleWatch, 5000, userStore.user.email, userStore.user.password)
                    return
                }
            }
        } catch(e) {
            const possibleServerError = ServerError.compare(e)
            if (possibleServerError) {
                AlertModel.add(possibleServerError.description, 'alert-danger')
                console.log('still no confirmed + maybe server dead');
                setTimeout(handleWatch, 5000, userStore.user.email, userStore.user.password)
                return
            }
        }
    } else { console.log("have no email and password for wathing auto-login"); }
}

// handle click forgot password
const handleForgot = async (props) => {
    try {
        console.log('otpravlyaem FORGOT PASSWORD REQUEST')
        const data = await fetchForgot(props.email)
        if (data.type && data.type === 'fetchError') { errorHandler(data.message, props.error, props.setIsLoading, props.setBtnEnabled); return }
        console.log(data)
        if (data && data.code === 22) {
            console.log('reset code was sent to email')
            userStore.setUser({email: props.email})
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            props.setScreen(ScreenType.resetCode)
        } else {
            const possibleServerError = ServerError.compare(data)
            if (possibleServerError || (data instanceof ServerError)) {
                props.setBtnEnabled(true)
                props.setIsLoading(false)
                props.error(data.description)
                return
            }
            console.log('no data while fetch sent password reset code, maybe db was down');
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            props.error(lang.authServerNotResponded)
        }
    } catch(err) {
        console.log('Some error while sending password reset code:');
        console.log(err);
        if (err instanceof ServerError) {
            props.error(err.description)
        }
        props.setBtnEnabled(true)
        props.setIsLoading(false)
    }
}

// handle match code from email and from database
const handleReset = async (props) => {
    try {
        console.log('otpravlyaem RESET PASSWORD CODE for matching')
        const data = await fetchForgot(props.email, props.code)
        if (data.type && data.type === 'fetchError') { errorHandler(data.message, props.error, props.setIsLoading, props.setBtnEnabled); return }
        console.log(data)
        if (data && data.code === 23) {
            console.log('Codes mached, show reset form')
            userStore.setUser({email: props.email, code: props.code})
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            props.setScreen(ScreenType.changePassword)
        } else {
            const possibleServerError = ServerError.compare(data)
            if (possibleServerError || (data instanceof ServerError)) {
                props.setBtnEnabled(true)
                props.setIsLoading(false)
                props.error(data.description)
                return
            }
            console.log('no data while matching codes, maybe db was down');
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            props.error(lang.authServerNotResponded)
        }
    } catch(err) {
        console.log('Some error while sending password reset code:');
        console.log(err);
        if (err instanceof ServerError) {
            props.error(err.description)
        }
        props.setBtnEnabled(true)
        props.setIsLoading(false)
    } 
}

// handle change password
const handleChangePassword = async (props) => {
    if (props.password !== props.password2) { errorHandler(lang.authResetPasswordMustBeSame, props.error, props.setIsLoading, props.setBtnEnabled); return }
    console.log('otpravlyaem CHANGE PASSWORD')
    const data = await fetchForgot(props.email, props.code, props.password)
    if (data.type && data.type === 'fetchError') { errorHandler(data.message, props.error, props.setIsLoading, props.setBtnEnabled); return }
    console.log(data)
    if (data && data.code === 24) {
        console.log('password was changed')
        props.setBtnEnabled(true)
        props.setIsLoading(false)
        props.setScreen(ScreenType.login)
    } else {
        const possibleServerError = ServerError.compare(data)
        if (possibleServerError || (data instanceof ServerError)) {
            props.setBtnEnabled(true)
            props.setIsLoading(false)
            props.error(data.description)
            return
        }
        console.log('no data while matching codes, maybe db was down');
        props.setBtnEnabled(true)
        props.setIsLoading(false)
        props.error(lang.authServerNotResponded)
    }
}

// error handler
const errorHandler = (message, error, setIsLoading, setBtnEnabled) => {
    console.log("Error handler: " + message);
    error(message)
    setIsLoading(false)
    setBtnEnabled(true)
}

export { handleSubmit, handleResend, handleWatch }