import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from '../Spinner'

import './scss/index.scss'
import { ScreenType } from '.'
import lang from '../../translate/en_US'
import { ReactComponent as LogoFull } from '../../images/logo.svg'

export const Wrapper = ({subtitle, isLoading, children, setScreen, screenType}) => {
  return (
    <Container fluid="xs" className="d-flex flex-column align-items-center justify-content-center vh-100">
      <Row className='shadow rounded-3 p-3 bg-body position-relative justify-content-center' style={{width: "300px"}}>
        <Col xs="auto">
          <a href='https://guamlist.com'>
            <LogoFull className='logoFullAuth' />
          </a>
          <h2 className='pt-2 pb-2'>{subtitle}</h2>
        </Col>
        <div>{children}</div>
        <Spinner isVisible={isLoading} />
      </Row>
      { screenType === ScreenType.login || screenType === ScreenType.signup
        ?
          <Row className='mt-3 mb-2'>
            <a href='/' className='forgot-password' onClick={e => {
              e.preventDefault()
              setScreen(ScreenType.forgot)
            }}>{lang.authForgotPasswordLink}</a>
          </Row>
        :
        null
      }
      
      <Row className={ screenType === ScreenType.login || screenType === ScreenType.signup ? 'auth-cpy' : 'auth-cpy mt-4'}>
        © { new Date().getFullYear() } GuamList
      </Row>
    </Container>
  )
}
