import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './add.module.scss'
import Button from 'react-bootstrap/Button'
import { Photos } from './Photos'
import Form from 'react-bootstrap/Form'
import lang from '../../translate/en_US'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import InputGroup from 'react-bootstrap/InputGroup'
import { Link, useNavigate } from 'react-router-dom'
import { useCategories } from '../../hooks/useCategories'
import { useLocations } from '../../hooks/useLocations'
import Spinner from '../../components/Spinner'
import { AlertModel } from '../../components/Alert/AlertModel'
import endpoints from '../../api/endpoints'
import { UpgradeAlert } from '../../components/UpgradeAlert/UpgradeAlert'

export const AddPosting = () => {

  const navigation = useNavigate()
  const categories = useCategories()
  const locations = useLocations()
  const [images, setImages] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState(null)
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [price, setPrice] = useState(null)
  const [location, setLocation] = useState(null)
  const [isLoading, setIsLoading] = useState()
  const [isUpgrade, setIsUpgrade] = useState(false)
  const [upgradeType, setUpgradeType] = useState('total')

  const [validator, setValidator] = useState(
    {
      vCategory: false,
      vSubcategory: false,
      vFilters: {},
    }
  )

  // changed category field
  const categoryChanged = (e) => {
    if (categories) {
      setSelectedCategory(categories.find(elem => elem.slug === e.target.value))
    }
    setSelectedSubcategory(null)
    setSelectedFilters(null)
    setTitle(null)
    setValidator(e => {return {...e, vCategory: false, vSubcategory: false, vFilters: {} }})
  }

   // changed subcategory field
  const subcategoryChanged = (e) => {
    if (selectedCategory && selectedCategory.subcategories) {
      const subcat = selectedCategory.subcategories.find(elem => elem.slug === e.target.value)
      setSelectedSubcategory(subcat)
      // will set empty filters for this subcategory
      if (subcat && subcat.filters) {
        const newFilters = {}
        subcat.filters.map((f) => {newFilters[f.slug] = null; return true})
        setSelectedFilters(newFilters)
      } else {
        setSelectedFilters(null)
      }
      setTitle(null)
    }
    setValidator(e => {return {...e, vCategory: false, vSubcategory: false, vFilters: {} }})
  }

  // one of filters was changed
  const filtersChanged = (e, filterSlug) => {
    if (e.target.value === lang.formSelectAnyFilterPlaceholder) {
      setSelectedFilters((old) => {
        return {...old, [filterSlug]: null}
      })
    } else {
      setSelectedFilters((old) => {
        return {...old, [filterSlug]: e.target.value}
      })
      setValidator(e => {return {...e, vFilters: {...e.vFilters, [filterSlug]: false} }})
    }
  }

  // submit click
  const submitHandle = (e) => {
    e.preventDefault()

    // validation
    if (!selectedCategory) { setValidator(e => {return {...e, vCategory: true }}); return false; }
    if (selectedCategory && !selectedSubcategory) { setValidator(e => {return {...e, vSubcategory: true }}); return false; }

    // validate required filters
    if (selectedSubcategory.filters) { 
      for (const key in selectedFilters) {
        const fullFilter = selectedSubcategory.filters.find(e => e.slug === key)
        if (fullFilter && fullFilter.required && !selectedFilters[key]) {
          console.log('nado pole', key);
          setValidator(e => {return {...e, vFilters: {...e.vFilters, [key]: true} }})
          return false
        }
      }
    }

    // valid all
    setValidator(e => {return {...e, vCategory: false, vSubcategory: false, vFilters: {} }})

    setIsLoading(true)

    const formData = new FormData(e.target)

    images.map((i) => {
      formData.append('photos', i.file)
      return true
    })
    
    endpoints.addNewPosting(formData)
    .then((resonse) => {
      setIsLoading(false)
      if (resonse && resonse.data && resonse.data._id) {
        setIsLoading(false)
        navigation('/')
      } else {
        console.log('error while get response for make new posting');
        AlertModel.add('Something goes wrong', 'alert-danger')
        setIsLoading(false)
      }
    })
    .catch((error) => {
      console.log('error while add new posting', error);
      if (error.code && error.code === 80802) { setUpgradeType('total'); setIsUpgrade(true) }
      else if (error.code && error.code === 80803) { setUpgradeType('real-estate'); setIsUpgrade(true) }
      else if (error.code && error.code === 80804) { setUpgradeType('vehicles'); setIsUpgrade(true) }
      else AlertModel.add('Server error, try again later', 'alert-danger')
      setIsLoading(false)
    })

  }

  return (
    <Container className={`d-flex flex-column mt-4 justify-content-center ${styles.mainContainer}`} fluid="sm">
    { console.log(' ♻ Add posting page was rendered!') }

    <Row className={styles.mainRow}>
        <Col className={`border rounded-3 p-3 bg-body position-relative ${styles.mainCol}`}>
            <div className={styles.addWrapper}>
                <h2>Add new posting</h2>

                <Form onSubmit={submitHandle} >

                  <Photos images={images} setImages={setImages} />

                  <FloatingLabel controlId="category" label="Category" className='mb-2 mt-2'>
                    <Form.Select name='category' isInvalid={validator.vCategory} disabled={categories ? false : true} onChange={e => categoryChanged(e)}>
                      { categories 
                        ?
                          <>
                            <option>Select category</option>
                            {
                              categories.map((oneCat, i) => {
                                return (
                                  <option value={oneCat.slug} key={i}>{oneCat.title}</option>
                                )
                              })
                            }
                          </>
                        :
                          <option>Loading...</option>
                      }
                    </Form.Select>
                  </FloatingLabel>

                  <FloatingLabel controlId="subcategory" label="Subcategory" className='mb-2'>
                    <Form.Select name='subcategory' isInvalid={validator.vSubcategory} disabled={selectedCategory ? false : true} onChange={e => subcategoryChanged(e)} value={selectedSubcategory ? selectedSubcategory.slug : lang.formSelectSubcategoryPlaceholder} >
                      { categories
                        ?
                          selectedCategory
                          ?
                            <>
                            <option>{lang.formSelectSubcategoryPlaceholder}</option>
                            { selectedCategory.subcategories 
                              &&
                                selectedCategory.subcategories.map((oneSubcat, i) => {
                                  return (
                                    <option value={oneSubcat.slug} key={i}>{oneSubcat.title}</option>
                                  )
                                })
                            }
                            </>
                          :
                            <option>Select category first</option>
                        :
                        <option>Loading...</option>
                      }
                      {selectedCategory && selectedCategory.title}
                    </Form.Select>
                  </FloatingLabel>

                  { (selectedSubcategory && selectedSubcategory.filters) &&
                    selectedSubcategory.filters.map((oneFilter, i) => {
                      return (
                        <FloatingLabel controlId={`filter_${oneFilter.slug}`} key={i} label={oneFilter.title} className='mb-2'>
                          <Form.Select onChange={e => filtersChanged(e, oneFilter.slug)} name={`filters[${oneFilter.slug}]`} isInvalid={validator.vFilters[oneFilter.slug] ? true : false}>
                          <option>{lang.formSelectAnyFilterPlaceholder}</option>
                            {
                              oneFilter.options.map((oneOption, ii) => {
                                return <option key={ii} value={oneOption.slug}>{oneOption.title}</option>
                              })
                            }
                          </Form.Select>
                        </FloatingLabel>
                      )
                    })
                  }

                  { (selectedSubcategory && !selectedSubcategory.noTitle) &&
                    <FloatingLabel controlId="title" label="Title" className='mb-2'>
                      <Form.Control required name='title' type='text' minLength={3} placeholder='Title' maxLength={150} onChange={e => setTitle(e.target.value)} value={title ? title : ''} />
                    </FloatingLabel>
                  }

                  <InputGroup className="mb-2">
                    <InputGroup.Text className={styles.currency}><i className="bi bi-currency-dollar"></i></InputGroup.Text>
                    <Form.Control className={styles.currencyFiled} name='price' id='price' required type='number' placeholder='Price' min={0} onChange={e => setPrice(e.target.value)} value={price ? price : ''} />
                  </InputGroup>

                  <FloatingLabel controlId="description" label="Description" className='mb-2'>
                    <Form.Control required name='description' as="textarea" placeholder="Description" style={{ height: '120px' }} onChange={e => setDescription(e.target.value)} value={description ? description : ''}/>
                  </FloatingLabel>

                  <FloatingLabel controlId="location" label="Location" className='mb-2'>
                    <Form.Select name='location' onChange={e => setLocation(e.target.value !== lang.formSelectLocationPlaceholder ? e.target.value : null)} disabled={locations ? false : true}>
                      { locations ?
                        <>
                          <option>{lang.formSelectLocationPlaceholder}</option>
                          {
                            locations.map((oneLoc, i) => {
                              return (
                                <option value={oneLoc.slug} key={i}>{oneLoc.title}</option>
                              )
                            })
                          }
                        </>
                      :
                        <option>Loading...</option>
                      }
                    </Form.Select>
                  </FloatingLabel>

                  <div className={styles.buttons}>
                    <Button type="submit">Publish</Button>
                    <Link to={'/'}>
                      <Button variant='danger'>Cancel</Button>
                    </Link>
                  </div>

                  </Form>
{/* 
                <div>
                  Category: {selectedCategory ? selectedCategory.title : '-'}<br/>
                  Subcategory: {selectedSubcategory ? selectedSubcategory.title : '-'}<br/>
                  Filters: {
                    selectedFilters ? 
                      keks(selectedFilters).map((e, i) => <div key={i}>{e}</div>)
                    :
                      "-"
                  }<br/>
                  Title: {title ? title : '-'}<br/>
                  Price: {price ? price : '-'}<br/>
                  Description: {description ? description : '-'}<br/>
                  Location: {location ? location : '-'}<br/>
                  Photos: {
                    images.length > 0 ? 
                      images.map((oneImg, i) => {
                        return (
                          <img src={oneImg.data_url} key={i} alt='' width={40} height={40}  />
                        )
                      })
                    :
                    "-"
                  }
                </div> */}

            </div>

            <Spinner isVisible={isLoading ? true : false} />

        </Col>
    </Row>

    <UpgradeAlert isVisible={isUpgrade} type={upgradeType} closeModal={ e => setIsUpgrade(false) } />

    </Container>
  )
}

// TODO: remove
// function keks(filters) {
//   let f = []

//   for (const key in filters) {
//     if (Object.hasOwnProperty.call(filters, key)) {
//       f.push(`${key} : ${filters[key]}`)
//     }
//   }
  
//   return f
// }