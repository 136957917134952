import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styles from './DashboardWarning.module.scss'

export const DashboardWarning = ({children}) => {
  return (
    <Row className={`mb-3 m-auto ${styles['dashboard-warning']}`} style={{minWidth: '300px', maxWidth: '300px'}}>
        <Col className='border rounded-3 p-3 bg-warning position-relative '>
          {children}
        </Col>
    </Row>
  )
}
