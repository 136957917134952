import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import React, { useRef, useState } from 'react'
import { handleSubmit } from '../handler'
import { ScreenType } from '..'
import { userStore } from '../../../App'
import lang from '../../../translate/en_US'

export const ChangePasswordForm = ({setScreen, setIsLoading}) => {

    const form = useRef()
    const errorPlaceholder = useRef(null)
    const [passwordField, setPasswordField] = useState('')
    const [password2Field, setPassword2Field] = useState('')
    const [validated, setValidated] = useState(false)
    const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state
    
    const email = userStore.user && userStore.user.email ? userStore.user.email : ''
    const code = userStore.user && userStore.user.code ? userStore.user.code : ''

    // make UI error / clear UI error if message = null
  const error = (message = null) => {
    if (message) {
      errorPlaceholder.current.innerHTML = `<div class="alert alert-danger mt-2" role="alert">${message}</div>`
    } else {
      errorPlaceholder.current.innerHTML = ''
    }
  }

  // nedded for handlers logic
  const submitProps = {
    password: passwordField,
    password2: password2Field,
    code: code,
    email: email,                       // email that was typed
    screenType: ScreenType.changePassword,   // type of current scrren
    error: error,                       // callback for showing UI error message for user
    setValidated: setValidated,         // ????????????????????
    setIsLoading: setIsLoading,         // callback for toggle the spinner
    setScreen: setScreen,               // callback for change visible screen
    setBtnEnabled: setBtnEnabled        // callback for change btn disability
  }

  return (
    <>
        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event, submitProps) } id='auth-form' className='mb-2' ref={form} >

          <FloatingLabel controlId="formPassword" label={lang.formInputNewPasswordPlaceholder} className="mb-3">
              <Form.Control
                type='password'
                placeholder={lang.formInputNewPasswordPlaceholder}
                required
                minLength={8} maxLength={40}
                onChange={event => setPasswordField(event.target.value)}
                onPaste={event => event.preventDefault()}
                name='password'
                pattern='(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}'
              />
              <Form.Control.Feedback type="invalid">{lang.formInputPasswordValidationError}</Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel controlId="formPassword2" label={lang.formInputRepeatPasswordPlaceholder} className="mb-3">
              <Form.Control
                type='password'
                placeholder={lang.formInputRepeatPasswordPlaceholder}
                required
                minLength={8} maxLength={40}
                onChange={event => setPassword2Field(event.target.value)}
                onPaste={event => event.preventDefault()}
                name='password'
                pattern='(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}'
              />
              <Form.Control.Feedback type="invalid">{lang.formInputPasswordValidationError}</Form.Control.Feedback>
            </FloatingLabel>

            <div className='pb-1 pt-0 justify-content-center d-flex'>
                <Button type='submit' variant='primary' className='' disabled={!btnEnabled  ? 'disabled' : null} >{lang.formSetNewPasswordButton}</Button>
            </div>
        </Form>
        <div id="errorAlertPlaceholder" ref={errorPlaceholder}></div>
    </>
  )
}
