import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { UserStoreContext } from '../../App'
import styles from './Alert.module.scss'
import { OneAlert } from './OneAlert'

export const AlertZone = observer(() => {

    const store = useContext(UserStoreContext)

    return (
        <div className={styles.alertsZone}>
            {store.alerts.map((e, i) => {
                return (
                    <OneAlert message={e.message} type={e.type} key={e.id} id={e.id} />
                )
            })}
        </div>
    )
})
