import { FacebookLoginClient } from '@greatsumini/react-facebook-login'
import axios from 'axios'
import Cookies from 'js-cookie'
import { userStore } from '../../App'
import { fetchRefresh } from '../../components/Auth/fetcher'
import { ServerError } from '../ServerError'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_ADDR
})

axiosInstance.interceptors.request.use(
    (config) => {
        const authTokenFromCookie = Cookies.get("access_token")
        const authTokenFromJSON = userStore.accessToken

        if (authTokenFromCookie) {
            config.headers.authorization = `Bearer ${authTokenFromCookie}`
        } else if (authTokenFromJSON) {
            config.headers.authorization = `Bearer ${authTokenFromJSON}`
        }

        return config
    }, 
    (error) => {
        console.log('request interceptor ERROR was occured')
        console.log(error)
        Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use( 
    (response) => {
        console.groupCollapsed(' 🐇 interceptor response:')
            console.log(response) 
        console.groupEnd()

        return response
    },
    async (error) => {
        console.log(' ⛔ response interceptor ERROR was occured')

        console.log('error without json', error);

        const jsonError = error.toJSON ? error.toJSON() : null
        const originalRequest = error.config ? error.config : null // original request where error occured
        const errorResponse = error.response ? error.response : null

        console.log('LOG return full error JSON', jsonError) // LOG return full error
        console.log('err response', errorResponse)

        // if we get 502 status from server (timeout for chat)
        if (errorResponse && errorResponse.status === 502) {
            return Promise.resolve(errorResponse)
        }

        // possible server dead or no internet
        if(jsonError && jsonError.name === "Error" && jsonError.message === "Network Error") {
            console.log(ServerError.answers.SERVER_OFFLINE);
            originalRequest._retry = false; // try to fetch again
            return new Promise(resolve => setTimeout(e=>axiosInstance(originalRequest), 5000))
            // return Promise.reject(ServerError.answers.SERVER_OFFLINE)
        }

        // if we get 400 status from server
        if (errorResponse && errorResponse.status && errorResponse.status === 400) {
            if (errorResponse.data) {
                const isServerError = ServerError.isServerError(errorResponse.data)
                if (isServerError) return Promise.reject(isServerError)
                else return Promise.reject(errorResponse)
            }
        }

        // trying to upload too big file
        if (error.request && error.request.status && error.request.status === 413) {
            return Promise.reject(ServerError.answers.FILE_IS_TOO_BIG) 
        }

        // refresh tokens if 401 Unauthorized
        if (jsonError && jsonError.status && jsonError.status === 401 && !originalRequest._retry) {
            console.log(' ⛔ Unauthorized ERROR, need new tokens, set refresh in process');
            originalRequest._retry = true; // try to fetch again

            if (userStore.refreshInProcess === false) {
                userStore.setRefreshInProcess(true)
                try {
                    const data = await fetchRefresh() // data with token

                    if (data) { // check for errors from server
                        const possibleServerError = ServerError.compare(data)
                        console.log('dtawfr', possibleServerError);
                        if (possibleServerError) { 
                            if (possibleServerError.code === 63 || possibleServerError.code === 81) { // if no refresh token - unlogin
                                Cookies.remove('isa', { domain: 'guamlist.com' }) // remove auth cookie
                                Cookies.remove('access_token', { domain: 'guamlist.com' }) // remove access token from cookie
                                userStore.setAccessToken(null) // UNLOGIN USER (remove access token from memory)
                                userStore.setIsAuth(false) // refresh UI for unlogin
                                console.log('was unlogin, + no ALERT');
                                FacebookLoginClient.logout((res) => { console.log('logouted from facebook', res) })
                                userStore.setRefreshInProcess(false)
                                return Promise.reject(error)      
                            }
                            userStore.setRefreshInProcess(false)
                            return Promise.reject(possibleServerError) 
                        }
                    }

                    const newAccessTokenFromCookie = Cookies.get('access_token')
                    if (newAccessTokenFromCookie && newAccessTokenFromCookie !== '') {
                        console.log(`access token was grabbed from Cookie: ${newAccessTokenFromCookie}`);
                        userStore.setAccessToken(newAccessTokenFromCookie) // set new access token from cookie
                        userStore.setRefreshInProcess(false)
                    }else if (data.access_token) {
                        console.log(`access token was grabbed from JSON body: ${data.access_token}`);
                        userStore.setAccessToken(data.access_token) // set new access token from JSON answer
                        userStore.setRefreshInProcess(false)
                    }
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + userStore.accessToken;
                    return axiosInstance(originalRequest)
                } catch (er) {
                    console.log('popalse err:');
                    console.log(er);
                    userStore.setRefreshInProcess(false)
                }
                
                Promise.resolve('ura')
            } else {
                console.log('404 was occured, but we already trying to refresh');
                originalRequest._retry = false
                return axiosInstance(originalRequest)
            }
            
        }

        // Promise.reject(error)
    }
)

export default axiosInstance 