import React, { useState } from 'react'

import { CustomAvaButton } from './CustomAvaButton'
import { CustomDropDownLink } from './CustomDropDownLink'
import { LogoutBtn } from './LogoutBtn'
import lang from '../../translate/en_US'

export const CustomDropDown = ({isOpen, setIsOpen}) => {

  const [isLoading, setIsLoading] = useState(false)       // is logout trying in process

  return (
    <div className='d-flex flex-row-reverse flex-grow-1 mx-3 avaContainer'>
        <CustomAvaButton onCLick={() => setIsOpen(!isOpen)} />
        { isOpen ?
            <ul className={'dropdown-menu ' + (isOpen ? 'show' : null)}>

                <CustomDropDownLink to={'/profile'} closeMenu={ () => setIsOpen(false) }>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                  </svg>
                  {lang.userMenuProfileButton}
                </CustomDropDownLink>

                <CustomDropDownLink to={'/messages'} closeMenu={ () => setIsOpen(false) }>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                  </svg>
                  {lang.userMenuMessagesButton}
                </CustomDropDownLink>

                <CustomDropDownLink to={'/favorites'} closeMenu={ () => setIsOpen(false) }>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart" viewBox="-1 -2 18 18">
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                  </svg>
                  {lang.userMenuFavoritesButton}
                </CustomDropDownLink>

                <li><hr className="dropdown-divider" /></li>
                <LogoutBtn isLoading={isLoading} setIsLoading={setIsLoading} />
            </ul>
          : null
        }
    </div>
  )
}
