import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import { Placeholder } from '../../components/Placeholder'
import { UserStoreContext } from '../../App'
import { observer } from 'mobx-react-lite'
import lang from '../../translate/en_US'
import { Button } from 'react-bootstrap'
import { ModalAlert } from '../../components/ModalAlert/ModalAlert'
import { AddPhoneForm } from './AddPhoneForm'
import { PhonePresentation } from './PhonePresentation'
import { Ava } from './Ava'

export const Profile = observer(() => {

  const { user } = useContext(UserStoreContext)

  const isShowSetPhoneButton = user && user.profile && (!user.profile.phone || user.profile.phone === "") ? true : false
  const [isInAddingPhoneMode, setIsInAddingPhoneMode] = useState(false)

  return (
    <Container className='d-flex mt-4 justify-content-center'>
      { console.log(' ♻ profile page was rendered!') }
      <Row className='' style={{minWidth: '300px'}}>
        <Col className='border rounded-3 p-3 bg-body'>
          <Stack direction='horizontal'>
            <Ava />
            <div className='mx-3 fs-5 text-capitalize w-100'>
              { user && user.name && user.surname ? user.name + ' ' + user.surname : <Placeholder places={[3, 2]} /> }
            </div>
          </Stack>
          <hr />
          <Stack>
            <Stack direction='horizontal' gap={2} className='m-1'>
              <div style={{color: '#afafaf', fontSize: 14}}>{lang.profilePageName}</div>
              <div className='text-capitalize w-100'>{user && user.name ? user.name : <Placeholder places={[3]} /> }</div>
            </Stack>
            <Stack direction='horizontal' gap={2} className='m-1'>
              <div style={{color: '#afafaf', fontSize: 14}}>{lang.profilePageSurname}</div>
              <div className='text-capitalize w-100'>{user && user.surname ? user.surname : <Placeholder places={[3]} /> }</div>
            </Stack>
            { (user && !user.fromSocial) &&
              <Stack direction='horizontal' gap={2} className='m-1'>
                <div style={{color: '#afafaf', fontSize: 14}}>{lang.profilePageEmail}</div>
                <div className='w-100'>{user && user.email ? user.email : <Placeholder places={[7]} /> }</div>
              </Stack>
            }
            <Stack direction='horizontal' gap={2} className='m-1'>
              <div style={{color: '#afafaf', fontSize: 14}}>{lang.profilePagePhone}</div>
              <div className='w-100'>
                {
                  user && user.email && user.profile && user.profile.phone ? <PhonePresentation phone={user.profile.phone} openEdit={setIsInAddingPhoneMode} /> : ( isShowSetPhoneButton ? <Button size="sm" onClick={() => setIsInAddingPhoneMode(true)}>{lang.profileSetPhoneButton}</Button> : <Placeholder places={[3]} /> )  
                }
              </div>
            </Stack>  
          </Stack>
        </Col>
      </Row>
      { isInAddingPhoneMode ? 
        <ModalAlert isFooter={false} isHeader={false} closeModal={() => setIsInAddingPhoneMode(false)} >
          <AddPhoneForm close={() => setIsInAddingPhoneMode(false)} />
        </ModalAlert> : null }
    </Container>
  )
})
