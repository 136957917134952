import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import React from 'react';
import { render } from 'react-dom';

import App from './App';


FacebookLoginClient.loadSdk('en_US')
  .then(() => {
    console.log('FB SDK Loaded');
    FacebookLoginClient.getFB().init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v13.0'
    })
  })
  .catch((err) => {
    console.log('error while fb load SDK');
  })


render( 
  <React.StrictMode>
    <App />
  </React.StrictMode>
, document.getElementById('root'));