import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import React, { useRef, useState } from 'react'
import { handleSubmit } from '../handler'
import { ScreenType } from '..'
import lang from '../../../translate/en_US'

export const ForgotForm = ({setScreen, setIsLoading}) => {

    const form = useRef()
    const errorPlaceholder = useRef(null)
    const [emailField, setEmailField] = useState('')
    const [validated, setValidated] = useState(false)
    const [btnEnabled, setBtnEnabled] = useState(true)   // the resend button enable state
    

    // clear form, errors and email values
    const clearForm = () => {
        form.current.reset()          // clear all form fields
        setValidated(false)           // ??????????????????
        setEmailField("")             // set email state to empty
        error()                       // clear all visible errors
    }

    // make UI error / clear UI error if message = null
  const error = (message = null) => {
    if (message) {
      errorPlaceholder.current.innerHTML = `<div class="alert alert-danger mt-2" role="alert">${message}</div>`
    } else {
      errorPlaceholder.current.innerHTML = ''
    }
  }

  // nedded for handlers logic
  const submitProps = {
    email: emailField,              // email that was typed
    screenType: ScreenType.forgot,  // type of current scrren
    error: error,                   // callback for showing UI error message for user
    setValidated: setValidated,     // ????????????????????
    setIsLoading: setIsLoading,     // callback for toggle the spinner
    setScreen: setScreen,           // callback for change visible screen
    setBtnEnabled: setBtnEnabled    // callback for change btn disability
  }

  return (
    <>
        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event, submitProps) } id='auth-form' className='mb-2' ref={form} >
            <FloatingLabel controlId="formForgot" label={lang.formInputEmailPlaceholder} className="mb-3">
            <Form.Control
                type='email'
                placeholder={lang.formInputEmailPlaceholder}
                required
                minLength={6} maxLength={100}
                onChange={event => setEmailField(event.target.value)}
                onPaste={event => event.preventDefault()}
                name='email'
                pattern='(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))'
                />
                <Form.Control.Feedback type="invalid">{lang.formInputEmailValidationError}</Form.Control.Feedback>
            </FloatingLabel>

            <div className='hstack gap-3 pb-1 pt-0'>
                <a href='/' onClick={ event => { 
                                            event.preventDefault()
                                            if(btnEnabled) {
                                                setScreen(ScreenType.login)
                                                clearForm(event) 
                                            }
                                            }}> 
                {lang.formBackToLogin}
                </a>
                <Button type='submit' variant='primary' className='ms-auto' disabled={!btnEnabled  ? 'disabled' : null} >{lang.formSendResetCodeButton}</Button>
            </div>
        </Form>
        <div id="errorAlertPlaceholder" ref={errorPlaceholder}></div>
    </>
  )
}
